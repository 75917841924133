import {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import {Autocomplete, TextField} from "@mui/material";
import {IState} from "../../../../../data/types";
import {useAuth} from "../../../auth";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {remoteRoutes} from "../../../../../data/constants";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {PRODUCTS_CONSTANTS} from "../../../../../data/redux/settings/productsReducer";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ICostCentre, IProcess} from "../../interfaces/IHub";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {get, post} from "../../../../../utils/ajax";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ISystemProduct} from "../../products/interfaces/ISystemProduct";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {ProcessSchema} from "../interfaces/IProcessSchema";

type Props = {
    hubId?: string
}

const CreateProcess = ({hubId}: Props) => {
    const products = useSelector((state: IState) => state.products.data);
    const costCentres = useSelector((state: IState) => state.settings.costCenters);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCostCentres, setSelectedCostCentres] = useState([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: null,
        products: [],
        costCenters: []
    }

    useEffect(() => {
        // handleFetchProducts()
        // handleFetchCostCentres()
    }, []);

    const handleFetchProducts = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/products');

            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;

                    dispatch({
                        type: PRODUCTS_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: PRODUCTS_CONSTANTS.STOP_FETCH});
                });
        }
    }
    const handleFetchCostCentres = () => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            let params: any = {hubId};

            const url = buildUrl(remoteRoutes.onboardingService, '/cost/centres', params);
            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_COST_CENTRES,
                        payload: data
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                });
        }
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/processes')
        const data: IProcess = {
            hubId: hubId ?? currentUser?.hubId,
            products: values.products,
            name: values.name,
            costCentres: values.costCentres
        }

        post(url, data, (response) => {

            CloseModal("add-process", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_PROCESSES,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setSelectedProducts([])
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ProcessSchema}
        >
            {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => {
                return <ModalWrapper
                    id="add-process"
                    title="Add a Process"
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12 mb-4">
                            <Field name="products">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: ISystemProduct) => option.name}
                                        options={products}
                                        onChange={(event, value) => {
                                            setFieldValue('products', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name
                                            })));
                                        }}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select products"
                                                variant="standard"
                                                error={touched.products && Boolean(errors.products)}
                                                helperText={touched.products && errors.products ? errors.products : ''}
                                            />
                                        )}/>
                                )}
                            </Field>
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Description"
                                name="description"
                            />
                        </div>

                        <div className="col-lg-12 mb-4">
                            <Field name="costCentres">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: ICostCentre) => option.name}
                                        options={costCentres}
                                        onChange={(event, value) => {
                                            setFieldValue('costCentres', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name
                                            })));
                                        }}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select cost centres"
                                                variant="standard"
                                                error={touched.costCenters && Boolean(errors.costCenters)}
                                                helperText={touched.costCenters && errors.costCenters ? errors.costCenters : ''}
                                            />
                                        )}/>
                                )}
                            </Field>
                        </div>

                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default CreateProcess;
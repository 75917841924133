import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

const InputsSalesProgressBar = () => {
    return (
        <>
            <div className="col-12 card mb-3" style={{
                minHeight: 445,
                borderRadius: 12,
                display: "flex",
                flexDirection: "column"
            }}>
                <div className="p-3"
                     style={{color: "#000000", fontSize: 18, fontWeight: 500}}>Sales ()
                </div>
                <div className="p-3">
                    <div className="">
                        <div className="row justify-content-between">
                            <div
                                className={"col d-flex align-items-center"}
                                style={{display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    height: 12,
                                    width: 12,
                                    borderRadius: 50,
                                    backgroundColor: "#AA0050"
                                }}></div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#616162",
                                    paddingLeft: 5
                                }}>Maize Seeds
                                </div>
                            </div>

                            <div className="col-3 align-items-center text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162",
                                     paddingRight: -10
                                 }}>31.4%
                            </div>
                        </div>
                        <ProgressBar
                            completed={31.4}
                            customLabel=" "
                            bgColor="#AA0050"
                            labelColor="transparent"
                            height="12px"
                        />
                        <div className="row justify-content-between">
                            <div className="col-5"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#AA0050"
                                 }}>In-stock: <b>32 MT</b>
                            </div>
                            <div className="col-5 text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162"
                                 }}>Sourced: <b>102 MT</b>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="row justify-content-between">
                            <div
                                className={"col d-flex align-items-center"}
                                style={{display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    height: 12,
                                    width: 12,
                                    borderRadius: 50,
                                    backgroundColor: "#5F1ED9"
                                }}></div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#616162",
                                    paddingLeft: 5
                                }}>Round Up
                                </div>
                            </div>

                            <div className="col-3 align-items-center text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162",
                                     paddingRight: -10
                                 }}>18%
                            </div>
                        </div>
                        <ProgressBar
                            completed={18}
                            customLabel=" "
                            bgColor="#5F1ED9"
                            labelColor="transparent"
                            height="12px"
                        />
                        <div className="row justify-content-between">
                            <div className="col-5"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#5F1ED9"
                                 }}>In-stock: <b>18 L</b>
                            </div>
                            <div className="col-5 text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162"
                                 }}>Sourced: <b>100 L</b>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="row justify-content-between">
                            <div
                                className={"col d-flex align-items-center"}
                                style={{display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    height: 12,
                                    width: 12,
                                    borderRadius: 50,
                                    backgroundColor: "#34C38F"
                                }}></div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#616162",
                                    paddingLeft: 5
                                }}>Gumboots
                                </div>
                            </div>

                            <div className="col-3 align-items-center text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162",
                                     paddingRight: -10
                                 }}>75%
                            </div>
                        </div>
                        <ProgressBar
                            completed={75}
                            customLabel=" "
                            bgColor="#34C38F"
                            labelColor="transparent"
                            height="12px"
                        />
                        <div className="row justify-content-between">
                            <div className="col-5"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#34C38F"
                                 }}>In-stock: <b>900 Pairs</b>
                            </div>
                            <div className="col-5 text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162"
                                 }}>Sourced: <b>1200 Pairs</b>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="row justify-content-between">
                            <div
                                className={"col d-flex align-items-center"}
                                style={{display: "flex", flexDirection: "row"}}>
                                <div style={{
                                    height: 12,
                                    width: 12,
                                    borderRadius: 50,
                                    backgroundColor: "#DF901F"
                                }}></div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: "#616162",
                                    paddingLeft: 5
                                }}>Bloom buddy
                                </div>
                            </div>

                            <div className="col-3 align-items-center text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162",
                                     paddingRight: -10
                                 }}>50%
                            </div>
                        </div>
                        <ProgressBar
                            completed={50}
                            customLabel=" "
                            bgColor="#DF901F"
                            labelColor="transparent"
                            height="12px"
                        />
                        <div className="row justify-content-between">
                            <div className="col-5"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#DF901F"
                                 }}>In-stock: <b>60 Pieces</b>
                            </div>
                            <div className="col-5 text-end"
                                 style={{
                                     fontSize: 10,
                                     fontWeight: 500,
                                     color: "#616162"
                                 }}>Sourced: <b>120 Pieces</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"card-footer bg-white"} style={{marginTop: "auto"}}>
                    <center style={{fontSize: 12, fontWeight: 500}}>
                        <a href="/inputs/sales" style={{color: "#1C5838"}}>See More
                            Inventory Sales</a>
                    </center>
                </div>
            </div>
        </>
    )
}

export default InputsSalesProgressBar;
import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React, {useState} from "react";
import {ICollectionCentre, IInputCategory, IProcess} from "../interfaces/IHub";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {env} from "../../../../data/constants";
import MapComponent from "../../../components/shared/MapComponent";
import EditCollectionCentreForm from "./modals/EditCollectionCentreForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteCollectionCentreForm from "./modals/DeleteCollectionCentreForm";

type Props = {
    close: () => void,
    show: boolean,
    collectionCentre: ICollectionCentre
}

const CostCentreDetails = ({close, show, collectionCentre}: Props) => {
    const key = env.googleMapsApiKey ?? '';
    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-collection-centre",
            dataToggle: "modal"
        },
        {
            // label: "Delete",
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-collection-centre",
            dataToggle: "modal",
            deleteIcon: true
        }
    ];

    return (
        <>
            <XSidebarWrapper title={"Collection Centre Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{collectionCentre?.name.substring(0, 2).toUpperCase()}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{collectionCentre?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span className="badge text-bg-danger">
                                    {collectionCentre?.district}
                                </span>
                                <span className="badge badge-danger">
                                    37 farmers
                                </span>
                            </div>

                            <div className="action-buttons">

                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash}/>}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Sub-County</strong><br/>
                            <span>{collectionCentre?.subCounty}</span>
                        </div>

                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Parish</strong><br/>
                            <span>{collectionCentre?.parish}</span>
                        </div>

                        <div className="col-lg-6 col-12 ">
                            <strong className="small fw-semibold">Village</strong><br/>
                            <span>{collectionCentre?.village}</span>
                        </div>

                    </div>
                </div>

                <div className="bg-gray border-1 rounded-2">
                    <LoadScript googleMapsApiKey={key}>
                        <MapComponent lat={collectionCentre?.latitude ?? 0} lng={collectionCentre?.longitude ?? 0}/>
                    </LoadScript>
                </div>

            </XSidebarWrapper>

            <EditCollectionCentreForm initialValues={collectionCentre}/>
            <DeleteCollectionCentreForm item={collectionCentre}/>
        </>
    );
}

export default CostCentreDetails;
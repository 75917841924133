import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PageHeaderLoader} from "../../components/shared/loaders/PageHeaderLoader";
import {IInputsDistributionState} from "../../../data/redux/inputs/inputsReducer";
import {IState} from "../../../data/types";
import {InnerMenu} from "../../components/shared/InnerMenu";
import {Modules} from "../../interfaces/Enums";
import DashboardLoader from "../../components/shared/loaders/DashboardLoader";
import InputSalesGraph from "./graphs/InputSalesGraph";
import InputSalesPerformanceGraph from "./graphs/InputSalesPerformanceGraph";
import InputsInventoryAndSalesGraph from "./graphs/InputsInventoryAndSalesGraph";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDays, faCaretDown, faDollarSign, faFilter, faShop} from "@fortawesome/free-solid-svg-icons";
import InventoryLedgerTable from "./graphs/InventoryLedgerTable";
import InputsStockProgressBar from "./graphs/InputsStockProgressBar";
import InputsSalesProgressBar from "./graphs/InputsSalesProgressBar";


export const InputDistributionsDashboard = () => {
    const {loading}: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Maize Seeds");

    const items = ["Maize Seeds", "Wheat", "Rice", "Barley"];

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleItemClick = (item: React.SetStateAction<string>) => {
        setSelectedItem(item);
        setIsOpen(false);
    };

    return (
        <>
            {loading && <PageHeaderLoader/>}
            <div>
                <div className={"bg-white align-content-center justify-content-center"}
                     style={{height: "10vh", width: "100%"}}>
                    <InnerMenu module={Modules.Inputs}/>
                </div>
                <div className={"bg-white align-content-center justify-content-center mt-1"}
                     style={{height: "10vh", width: "100%"}}>
                    <div className="row justify-content-between"
                         style={{width: "100%",}}>
                        <div className='col-7 align-content-center justify-content-center text-center'>
                            <div className="row">
                                <div className='col' style={{fontWeight: 500, fontSize: 20}}>Overview</div>
                                <div className='col align-content-center justify-content-center text-center'
                                     style={{backgroundColor: "#EAEBEB", borderRadius: 20}}>
                                    <span><FontAwesomeIcon icon={faCalendarDays} color={'#1F5A2D'} size={"sm"}/></span>
                                    <span className={"p-1"} style={{fontWeight: 400, fontSize: 12, color: '#1F5A2D'}}>Last 30 Days</span>
                                </div>
                                <div className='col align-content-center justify-content-center text-center'
                                     style={{
                                         backgroundColor: "#EAEBEB",
                                         borderRadius: 20,
                                         marginLeft: 10,
                                     }}>
                                    <span><FontAwesomeIcon icon={faShop} color={'#1F5A2D'} size={"sm"}/></span>
                                    <span className={"p-1"} style={{fontWeight: 400, fontSize: 12, color: '#1F5A2D'}}>All Collection Centers</span>
                                </div>
                            </div>
                        </div>
                        <button className='col-4 align-content-center justify-content-center text-center'
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: 50,
                                    width: 40,
                                    height: 40,
                                    borderWidth: "1px",
                                    borderColor: "#1F5A2D",
                                    border: "#1F5A2D",
                                    borderStyle: "solid"
                                }}>
                            <span><FontAwesomeIcon icon={faFilter} color={"#1F5A2D"} size={"lg"}/></span>
                        </button>
                    </div>
                </div>
                <div className={"mt-2"}>
                    {loading && <DashboardLoader/>}
                    {!loading &&
                        <>
                            <div className={"row p-3 d-flex justify-content-between gap-3"}>
                                <div className="col-4">
                                    <div className="row">
                                        <InputsStockProgressBar/>
                                        <InputsSalesProgressBar/>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="row mb-3 d-flex justify-content-between gap-2">
                                        <div className="col card bg-primary dashboard-smaller-card">
                                            <div
                                                className="row d-flex justify-content-center align-items-center"
                                                style={{height: '30vh'}}
                                            >
                                                <div className="col-7 text-center">
                                                    <div style={{fontWeight: 600, fontSize: 12}}>Amount from Sales</div>
                                                    <span className="d-flex justify-content-between align-items-center">
                                                        <div style={{fontWeight: 600, fontSize: 16}}>UGX </div>
                                                        <div style={{fontWeight: 600, fontSize: 28}}><b> 13.7M</b></div>
                                                    </span>
                                                </div>
                                                <div className="col-5 d-flex justify-content-center align-items-center">
                                                    <div
                                                        className="bg-white d-flex justify-content-center align-items-center dashboard-smaller-circle">
                                                        <div
                                                            className="bg-orange d-flex justify-content-center align-items-center"
                                                            style={{width: 20, height: 20, borderRadius: 50}}
                                                        >
                                                            <FontAwesomeIcon icon={faDollarSign}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col card bg-primary dashboard-smaller-card">
                                            <div
                                                className="row d-flex justify-content-center align-items-center"
                                                style={{height: '30vh'}}>
                                                <div className="col-7 text-center">
                                                    <div style={{fontWeight: 600, fontSize: 12}}>Cost of Inputs</div>
                                                    <span className="d-flex justify-content-between align-items-center">
                                                        <div style={{fontWeight: 600, fontSize: 16}}>UGX </div>
                                                        <div style={{fontWeight: 600, fontSize: 28}}><b> 7.2M</b></div>
                                                    </span>
                                                </div>
                                                <div className="col-5 d-flex justify-content-center align-items-center">
                                                    <div
                                                        className="bg-white d-flex justify-content-center align-items-center dashboard-smaller-circle">
                                                        <div
                                                            className="bg-orange d-flex justify-content-center align-items-center"
                                                            style={{width: 20, height: 20, borderRadius: 50}}
                                                        >
                                                            <FontAwesomeIcon icon={faDollarSign}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col card bg-primary dashboard-smaller-card">
                                            <div
                                                className="row d-flex justify-content-center align-items-center"
                                                style={{height: '30vh'}}>
                                                <div className="col-7 text-center">
                                                    <div style={{fontWeight: 600, fontSize: 12}}>Profit from sales</div>
                                                    <span className="d-flex justify-content-between align-items-center">
                                                        <div style={{fontWeight: 600, fontSize: 16}}>UGX </div>
                                                        <div style={{fontWeight: 600, fontSize: 28}}><b> 3.5M</b></div>
                                                    </span>
                                                </div>
                                                <div className="col-5 d-flex justify-content-center align-items-center">
                                                    <div
                                                        className="bg-white d-flex justify-content-center align-items-center dashboard-smaller-circle">
                                                        <div
                                                            className="bg-orange d-flex justify-content-center align-items-center"
                                                            style={{width: 20, height: 20, borderRadius: 50}}
                                                        >
                                                            <FontAwesomeIcon icon={faDollarSign}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3 d-flex justify-content-between gap-2">
                                        <div className="col card"
                                             style={{height: 362, borderRadius: 12}}>

                                            <div className="row justify-content-between p-3">
                                                <div className="col-9">
                                                    <h6>Inputs Inventory Vs Input Sales</h6>
                                                </div>
                                                <div className="col-3 align-items-center text-center"
                                                     style={{position: "relative",}}>
                                                    <button
                                                        onClick={toggleDropdown}
                                                        className="align-items-center text-center"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            backgroundColor: "#F5F5F5",
                                                            borderWidth: "1px",
                                                            borderColor: "#EAEAEA",
                                                            borderStyle: "solid",
                                                            borderRadius: 4,
                                                            cursor: "pointer",
                                                            width: "100%",
                                                            padding: "5px",
                                                        }}>
                                                        <span>{selectedItem}</span>
                                                        <span style={{marginLeft: "5%"}}>
                                                            <FontAwesomeIcon
                                                                icon={faCaretDown}
                                                                size={"sm"}
                                                                color={"#414241"}/>
                                                        </span>
                                                    </button>

                                                    {isOpen && (
                                                        <ul
                                                            style={{
                                                                position: "absolute",
                                                                top: "100%",
                                                                left: 0,
                                                                backgroundColor: "#F5F5F5",
                                                                border: "1px solid #EAEAEA",
                                                                borderRadius: 4,
                                                                marginTop: 5,
                                                                listStyle: "none",
                                                                padding: 0,
                                                                width: "100%",
                                                                zIndex: 1,
                                                            }}>
                                                            {items.map((item) => (
                                                                <li
                                                                    key={item}
                                                                    onClick={() => handleItemClick(item)}
                                                                    style={{
                                                                        padding: "8px 12px",
                                                                        cursor: "pointer",
                                                                        borderBottom: "1px solid #EAEAEA",
                                                                        color: selectedItem === item ? "#414241" : "#000",
                                                                    }}>
                                                                    {item}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            <InputsInventoryAndSalesGraph/>
                                        </div>
                                    </div>

                                    <div className="row d-flex justify-content-between gap-3">
                                        <div className="col card " style={{height: 445, borderRadius: 12}}>
                                            <h6 className={"p-3"}>Inputs Sales Payments</h6>
                                            <InputSalesGraph/>
                                        </div>
                                        <div className="col card" style={{height: 445, borderRadius: 12}}>
                                            <h6 className={"p-3"}>Sales Performance</h6>
                                            <InputSalesPerformanceGraph/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row p-3 d-flex justify-content-between gap-3 mb-2"}>
                                <InventoryLedgerTable/>
                            </div>
                        </>
                    }
                </div>

            </div>

        </>
    );
};

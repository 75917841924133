import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PageHeaderLoader} from "../../components/shared/loaders/PageHeaderLoader";
import {IState} from "../../../data/types";
import {InnerMenu} from "../../components/shared/InnerMenu";
import {Modules} from "../../interfaces/Enums";
import DashboardLoader from "../../components/shared/loaders/DashboardLoader";
import {IPurchaseState} from "../../../data/redux/inventory/purchasesReducer";
import {InventoryVersusSalesGraph} from "./graphs/InventoryVersusSalesGraph";
import InventorySalesGraph from "./graphs/InventorySalesGraph";
import InventorySalesPerformanceGraph from "./graphs/InventorySalesPerformanceGraph";
import InventoryLedgerTable from "./graphs/InventoryLedgerTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faFilter,
    faShop,
    faCaretDown,
    faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import InventoryStockProgress from "./graphs/InventoryStockProgress";
import InventorySalesProgress from "./graphs/InventorySalesProgress";
import {PageHeader} from "../../components/shared/PageHeader";
import XSummaryCard from "../../components/shared/XSummaryCard";


export const Dashboard = () => {
    const {loading}: IPurchaseState = useSelector((state: IState) => state.purchases);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Maize Seeds");

    const items = ["Maize Seeds", "Wheat", "Rice", "Barley"];

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleItemClick = (item: React.SetStateAction<string>) => {
        setSelectedItem(item);
        setIsOpen(false);
    };

    return (
        <>
            {loading && <>
                <PageHeaderLoader/>
                <DashboardLoader/>
            </>}

            {!loading && <>

                <PageHeader title={"Inventory > Overview"}>
                    <InnerMenu module={Modules.Inventory}/>
                </PageHeader>

                <div className="row mb-3">
                    <div className="col-12">
                        <div className="card border-0">
                            <div className="card-body d-flex w-100 justify-content-between">
                                <div className="d-flex justify-content-start align-items-center w-75">
                                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                                        <FontAwesomeIcon icon={faCalendarDays} size={"sm"}/>
                                        <span className={"p-1"}>Last 30 Days</span>
                                    </div>
                                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                                        <FontAwesomeIcon icon={faShop}/>
                                        <span className={"p-1"}>All Collection Centers</span>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-center w-25">
                                    <button className="btn btn-secondary">
                                        <FontAwesomeIcon icon={faFilter} color={"#1F5A2D"} className="me-2"/>
                                        Filters
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="card w-100 border-0 mb-3">
                            <div className="card-body">
                                <h3>Inventory (In-stock)</h3>
                                <InventoryStockProgress/>
                            </div>
                            <div className="card-footer text-center bg-white pt-2 pb-2">
                                <a href="/inventory/stock">See More</a>
                            </div>
                        </div>
                        <div className="card w-100 border-0 mb-3">
                            <div className="card-body">
                                <h3>Sales</h3>
                                <InventorySalesProgress/>
                            </div>
                            <div className="card-footer text-center bg-white pt-2 pb-2">
                                <a href="/inventory/sales">See More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row mb-3">
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Amount from Sales"}
                                    value={"13.7M"}
                                    icon={faMoneyBill}/>
                            </div>
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Amount from Sales"}
                                    value={"13.7M"}
                                    icon={faMoneyBill}/>
                            </div>
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Profit from Sales"}
                                    value={"3.5M"}
                                    icon={faMoneyBill}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="col card border-0" style={{height: 445, borderRadius: 12}}>
                                    <h6 className={"p-3"}>Inputs Inventory Vs Input Sales</h6>
                                    <InventoryVersusSalesGraph/>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="col card border-0" style={{height: 445, borderRadius: 12}}>
                                    <h6 className={"p-3"}>Inputs Sales Payments</h6>
                                    <InventorySalesGraph/>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="col card border-0" style={{height: 445, borderRadius: 12}}>
                                    <h6 className={"p-3"}>Sales Performance</h6>
                                    <InventorySalesPerformanceGraph/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <InventoryLedgerTable/>

            </>}

        </>
    );

};

import React, {MouseEventHandler} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";

export interface ITableColumn {
    label: string
    id: string
    link?: string
}

export interface ITableData {
    actions?: MouseEventHandler<HTMLButtonElement>
    rows: any[]
}

interface IProps {
    loading: boolean
    handleSearch: (event: any) => void
    value?: string
}

export const XTableFilter = ({handleSearch, value}: IProps) => {

    return (
        <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="input-group w-25">
                <input type="text" onKeyUp={(event) => handleSearch(event)} className="form-control"
                       placeholder={value ?? "Type to search..."}/>
                <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch}/>
                        </span>
            </div>

            <div className="action-buttons">
                <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                    <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                    <span className="ms-2">Filters</span>
                </button>
                <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                </button>
            </div>
        </div>
    );
}

export default XTableFilter;
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {Field, Formik} from "formik";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {useAuth} from "../../../auth";
import {useDispatch, useSelector} from "react-redux";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {IInputCategory, IUnit} from "../../interfaces/IHub";
import {IState} from "../../../../../data/types";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {ISystemProduct, SystemProductSchema} from "../../products/interfaces/ISystemProduct";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";

const CreateSystemProduct = () => {

    const auth = useAuth();
    const currentUser = auth?.currentUser;
    const dispatch = useDispatch();

    const units: IUnit[] = useSelector((state: IState) => state.settings.units);

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: null,
        imageUrl: null,
        description: null,
        units: [],
        category: null,
        otherProductCategory: ''
    }

    const productCategories = auth.categories?.productCategories;
    const categories = productCategories?.map((category: IInputCategory) => ({
        label: category.name,
        id: category.number
    }));

    useEffect(() => {
        // handleFetchData()
    }, []);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/units');

            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_UNITS,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                });
        }
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/products')
        const data: ISystemProduct = {
            name: values.name,
            scientificName: values.scientificName,
            brand: values.brand,
            imageUrl: values.imageUrl,
            category: values.category.id,
            units: values.units,
            description: values.description
        }

        post(url, data, (response) => {

            CloseModal("new-system-product", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_PRODUCTS,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={SystemProductSchema}
                onSubmit={handleSubmit}>

                {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => {
                    return <ModalWrapper
                        id="new-system-product"
                        title="Create a New Product"
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Name"
                                    name="name"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Scientific Name"
                                    name="scientificName"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Brand"
                                    name="brand"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    label="Category"
                                    options={categories}
                                    name="category"
                                    onChange={(event: any, value: {
                                        label: string,
                                        id: number
                                    }) => setFieldValue('category', value)}
                                    value={values.category}
                                />
                            </div>

                            {values.category && (values.category as any).label === 'Others' && (
                                <div className="col-lg-12">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Other Product Category"
                                        name="otherProductCategory"
                                    />
                                </div>
                            )}

                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Description"
                                    name="description"
                                />
                            </div>


                            <div className="col-lg-12">
                                <Field name="units">
                                    {() => (
                                        <Autocomplete
                                            multiple
                                            getOptionLabel={(option: IUnit) => option.name}
                                            options={units}
                                            onChange={(event, value) => {
                                                setFieldValue('units', value.map(prod => ({
                                                    id: prod.id,
                                                    name: prod.displayName
                                                })));
                                            }}

                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select units"
                                                    variant="standard"
                                                    error={touched.units && Boolean(errors.units)}
                                                    helperText={touched.units && errors.units ? errors.units : ''}
                                                />
                                            )}/>
                                    )}
                                </Field>
                            </div>

                            <div className="col-lg-12 mt-4">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Image Url"
                                    name="imageUrl"
                                />
                            </div>


                        </div>

                    </ModalWrapper>
                }}

            </Formik>

        </>
    );
}

export default CreateSystemProduct;
import {Autocomplete, TextField, TextFieldVariants} from "@mui/material";
import {ErrorMessage} from "formik";

export type IOption = {
    id: string | number
    label: string
    disabled?: boolean
}

type Props = {
    disabled?: boolean
    row: boolean
    field: any
    form: any
    label: string
    variant?: TextFieldVariants
}

export const XAutoComplete = ({label, field, form, disabled = false, variant="standard",  ...props}: Props) => {

    return (
        <Autocomplete
            disabled={disabled}
            {...props}
            {...field}
            getOptionLabel={(option: IOption) => option.label ?? ''}
            onChange={(event, value) => form.setFieldValue(field.name, value)}
            value={field.value}
            isOptionEqualToValue={(option: IOption, value: any) => option.id === value.id}
            variant="standard"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    sx={{
                        '& label.Mui-focused': {
                            color: '#1C5838'
                        },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#1C5838'
                            }
                        }
                    }}
                    value={field.value}
                    variant={variant ?? "standard"}
                    className="form-control mb-3"
                    error={form.touched[field.name] && Boolean(form.errors[field.name])}
                    helperText={<ErrorMessage name={field.name}/>}
                />
            )}
        />
    );
}
import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import {Autocomplete, TextField} from "@mui/material";
import {IState} from "../../../../../data/types";
import {useAuth} from "../../../auth";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {remoteRoutes} from "../../../../../data/constants";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {PRODUCTS_CONSTANTS} from "../../../../../data/redux/settings/productsReducer";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {HubProductSchema, IHubProduct, IIdNamePair, IProduct} from "../../interfaces/IHub";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {get, post} from "../../../../../utils/ajax";
import {IOption, XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {districts} from "../../../../../data/districts";

type Props = {
    hubId?: string
    items: IProduct[]
}

const AddProductForm = ({hubId, items}: Props) => {


    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState<IOption[] | undefined>([]);
    const {refreshHubDetails} = useAuth();

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;

    const userHub = auth.userHub;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        hubId: hubId ?? currentUser?.hubId,
        products: []
    }

    useEffect(() => {
        handleFetchData(1, 40)

        const productsList = userHub?.products?.map(p => ({
            id: p.id ?? '',
            label: p.name,
        }));

        if (productsList){
            setProducts(productsList)
        }

    }, [dispatch]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            let params: any = {page, pageSize};
            const url = buildUrl(remoteRoutes.onboardingService, '/products', params);

            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;

                    dispatch({
                        type: PRODUCTS_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: PRODUCTS_CONSTANTS.STOP_FETCH});
                });
        }
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/hubs/products')
        const data: IHubProduct = {
            hubId: values.hubId,
            products: values.products.map((product: any) => ({
                id: product.id,
                name: product.label
            }))
        }

        post(url, data, (response) => {

            CloseModal("add-product", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: PRODUCTS_CONSTANTS.POST_DATA,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            refreshHubDetails();
            setSelectedProducts([]);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={HubProductSchema}
        >
            {({handleSubmit, isSubmitting, touched, errors, values, setFieldValue}) => {
                return <ModalWrapper
                    id="add-product"
                    title="Add Products"
                    position="top"
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                multiple
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={products}
                                label="Products"
                                name="products"
                            />

                            {/*<Field name="products">*/}
                            {/*    /!*{() => (*!/*/}
                            {/*    /!*    // <Autocomplete*!/*/}
                            {/*    /!*    //     multiple*!/*/}
                            {/*    /!*    //     getOptionLabel={(option: IIdNamePair) => option.name}*!/*/}
                            {/*    /!*    //     options={productsList}*!/*/}
                            {/*    /!*    //     onChange={(event, value) => {*!/*/}
                            {/*    /!*    //         setFieldValue('products', value.map(prod => ({*!/*/}
                            {/*    /!*    //             id: prod.id,*!/*/}
                            {/*    /!*    //             name: prod.name*!/*/}
                            {/*    /!*    //         })));*!/*/}
                            {/*    /!*    //     }}*!/*/}
                            {/*    /!*    //*!/*/}
                            {/*    /!*    //     value={values.products}*!/*/}
                            {/*    /!*    //*!/*/}
                            {/*    /!*    //     renderOption={(props, option) => (*!/*/}
                            {/*    /!*    //         <li {...props} key={option.id}>*!/*/}
                            {/*    /!*    //             {option.name}*!/*/}
                            {/*    /!*    //         </li>*!/*/}
                            {/*    /!*    //     )}*!/*/}
                            {/*    /!*    //*!/*/}
                            {/*    /!*    //     renderInput={(params) => (*!/*/}
                            {/*    /!*    //         <TextField*!/*/}
                            {/*    /!*    //             {...params}*!/*/}
                            {/*    /!*    //             label="Select products"*!/*/}
                            {/*    /!*    //             variant="standard"*!/*/}
                            {/*    /!*    //             error={touched.products && Boolean(errors.products)}*!/*/}
                            {/*    /!*    //             // helperText={touched.products && errors.products ? errors.products : ''}*!/*/}
                            {/*    /!*    //         />*!/*/}
                            {/*    /!*    //     )}/>*!/*/}
                            {/*    /!*)}*!/*/}
                            {/*</Field>*/}
                        </div>
                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default AddProductForm;
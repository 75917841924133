import {Outlet, Route, Routes} from "react-router-dom";
import {Settings} from "./Settings";


const SettingsPage = () => {
    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<Settings/>}/>
            </Route>
        </Routes>
    );
}

export default SettingsPage;
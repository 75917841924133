import * as Yup from "yup";

export interface IInput {
    id: string
    hubId: string
    name: string
    category: string
    units: []
}

export interface IHubInput {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: string
    categoryName?: string
    otherInputCategory?: string,
    units: IdName [] | []
}

export interface IInputDto {
    id?: string
    hubId?: string
    name: string
    description?: string
    category: number
    otherInputCategory?: string,
    units?: IdName []
}

export interface IdName {
    id: string
    name: string
}

export const InputSchema = Yup.object({
    name: Yup.string().nullable().required('Required'),
    // category: Yup.object().shape({
    //     id: Yup.string().required('Required'),
    //     label: Yup.number().required('Required'),
    // }).nullable().required('Required'),
    hubId: Yup.string().required('Required')
})
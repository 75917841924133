import React from "react";
import {Box} from '@mui/material';
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Input Inventory",
            color: "#DF901F",
        },
        {
            label: "Input Sales",
            color: "#1C5838",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                         sx={{backgroundColor: item.color}}
                    />
                    <span style={{color: '#848484'}}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    )
};

const dataset = [
    {month: 'Jan', sales: 900000, inputs: 1202000},
    {month: 'Feb', sales: 4500000, inputs: 1800000},
    {month: 'Mar', sales: 6000000, inputs: 220000},
    {month: 'Apr', sales: 700000, inputs: 2500000},
    {month: 'May', sales: 800000, inputs: 1350000},
    {month: 'Jun', sales: 750000, inputs: 280000},
    {month: 'Jul', sales: 900000, inputs: 132000},
    {month: 'Aug', sales: 850000, inputs: 310000},
    {month: 'Sept', sales: 950000, inputs: 330000},
    {month: 'Oct', sales: 1000000, inputs: 340000},
    {month: 'Nov', sales: 1050000, inputs: 350000},
    {month: 'Dec', sales: 1100000, inputs: 3600000},
];

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000_000) {
        return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)}K`;
    } else {
        return `${value}`;
    }
};

export const InventoryVersusSalesGraph = () => {
    return (
        <>
            <Box>
                <BarChart
                    dataset={dataset}
                    borderRadius={6}
                    xAxis={[{scaleType: 'band', dataKey: 'month', disableTicks: true,}]}
                    series={[
                        {
                            dataKey: 'sales',
                            label: 'Sales',
                            color: '#DF901F',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            dataKey: 'inputs',
                            label: 'Inputs',
                            color: '#1C5838',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                    ]}
                    yAxis={[{
                        disableTicks: true,
                        valueFormatter: (value) => valueFormatter(value),
                    }]}
                    height={350}
                    margin={{
                        right: 20,
                        left: 60
                    }}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
                <CustomLegend/>
            </Box>
        </>
    )
}

import React, {MouseEventHandler} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BackButton} from "./BackButton";
import {IBreadcrumb} from "./Breadcrumbs";

export interface IButtonProps {
    label: string
    cssClass: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    processing?: boolean
    dataTarget?: string
    dataToggle?: string
    onSubmit?: (values: any) => void
}

interface IProps {
    title?: string
    actionButtons?: IButtonProps[]
    backArrow?: boolean
    children?: any
    prevUrl?: string
    breadcrumbs?: IBreadcrumb[]
}

export const PageHeader = (props: IProps) => {
    return (
        <div style={{minHeight: 80}} className="d-flex w-100 py-3 justify-content-between align-items-center">

            <div className="d-flex align-items-center">

                <nav role="presentation">
                    <ol className={"breadcrumb"}>
                        <li className={"breadcrumb-item"}>
                            <BackButton backArrow={true}/>
                        </li>
                        {props.breadcrumbs?.map((item, index) => {
                            return (
                                <li key={index} className={"breadcrumb-item"}>
                                    {item.url && <a href={item.url}>{item.label}</a>}
                                    {!item.url && <span>{item.label}</span>}
                                </li>
                            )
                        })}
                    </ol>
                </nav>

            </div>

            <div className="">
                {props.children}
            </div>


        </div>
    );
}
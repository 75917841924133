import {useDispatch} from "react-redux";
import {Field, Formik, useField} from "formik";
import {useEffect, useState} from "react";
import {useAuth} from "../../auth";
import {useParams} from "react-router-dom";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import {IBuyer} from "../interfaces/IBuyer";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {post} from "../../../../utils/ajax";
import {BUYERS_CONSTANTS} from "../../../../data/redux/inventory/buyersReducer";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {BuyerSchema} from "../schemas/BuyerSchema";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";

type Props = {
    hubId?: string
}

const CreateCollectionCentre = ({hubId}: Props) => {

    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const [district, setDistrict] = useState<string[]>([]);
    const urlParams = useParams()
    hubId = hubId ?? urlParams.hubId;

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: '',
        district: '',
        hubId: null,
        email: null,
        eoriNumber: null,
        address: null
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, '/buyers')
        const data: IBuyer = {
            name: values.name,
            hubId: hubId ?? currentUser?.hubId,
            email: values.email,
            eoriNumber: values.eoriNumber,
            address: values.address,
        }

        post(url, data, (response) => {

            CloseModal("new-buyer", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: BUYERS_CONSTANTS.POST_BUYER,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BuyerSchema}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="new-buyer"
                    title="Add a New Buyer"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="EORINumber"
                                name="eoriNumber"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Address"
                                name="address"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default CreateCollectionCentre;
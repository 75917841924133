import React from "react";
import {Box} from "@mui/material";
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Maize Seeds",
            color: "#AA0050",
        },
        {
            label: "Round Up",
            color: "#5F1ED9",
        },
        {
            label: "Gumboots",
            color: "#1F5A2D",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                         sx={{backgroundColor: item.color}}
                    />
                    <span style={{color: '#848484'}}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    )
};

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000_000) {
        return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)}K`;
    } else {
        return `${value}`;
    }
};

const InventorySalesPerformanceGraph = () => {

    return (
        <>
            <Box>
                <BarChart
                    series={[
                        {
                            data: [48_900, 390_000, 1_000_000, 520_000],
                            stack: 'A',
                            label: 'Maize Seeds',
                            color: '#AA0050',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            data: [300_000, 420_000, 130_900, 600_000],
                            stack: 'A',
                            label: 'Round Up',
                            color: '#5F1ED9',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            data: [700_000, 44_000, 10_000, 106_000],
                            stack: 'A',
                            label: 'Gumboots',
                            color: '#1C5838',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            data: [200_000, 1_400_000, 100_300, 67_000],
                            stack: 'A',
                            label: 'Bloom Buddy',
                            color: '#34C38F',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            data: [500_000, 400_000, 100_000, 2_000_000],
                            stack: 'A',
                            label: 'Insecticide',
                            color: '#DF901F',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                    ]}
                    xAxis={[{
                        scaleType: 'band',
                        data: ["Masaka \n" + "Store", "Lira \n" + "Store", "Main \n" + "Store", "Central \n" + "Store"],
                        disableTicks: true,
                    }]}
                    yAxis={[{
                        disableTicks: true,
                        valueFormatter: (value) => valueFormatter(value),

                    }]}
                    height={350}
                    margin={{
                        right: 20,
                        left: 60,
                    }}
                    borderRadius={8}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,

                        },
                    }}
                />


                <CustomLegend/>
            </Box>
        </>
    )
}
export default InventorySalesPerformanceGraph;

import React from "react";
import {Box} from '@mui/material';
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => (
    <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#DF901F',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Input Inventory</span>
        </Box>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#1C5838',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Input Sales</span>
        </Box>
    </Box>
);

const dataset = [
    {month: 'Jan', sales: 500, inputs: 200},
    {month: 'Feb', sales: 450, inputs: 180},
    {month: 'Mar', sales: 600, inputs: 220},
    {month: 'Apr', sales: 700, inputs: 250},
    {month: 'May', sales: 800, inputs: 300},
    {month: 'Jun', sales: 750, inputs: 280},
    {month: 'Jul', sales: 900, inputs: 320},
    {month: 'Aug', sales: 850, inputs: 310},
    {month: 'Sept', sales: 950, inputs: 330},
    {month: 'Oct', sales: 1000, inputs: 340},
    {month: 'Nov', sales: 1050, inputs: 350},
    {month: 'Dec', sales: 1100, inputs: 360},
];

const InputsInventoryAndSalesGraph = () => {
    return (
        <>
            <Box
                style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "auto",
                    maxHeight: 300,
                    marginTop: -30,
                }}
            >
                <BarChart
                    dataset={dataset}
                    borderRadius={6}
                    xAxis={[{scaleType: 'band', dataKey: 'month', disableTicks: true,}]}
                    series={[
                        {dataKey: 'sales', label: 'Sales', color: '#DF901F'},
                        {dataKey: 'inputs', label: 'Inputs', color: '#1C5838'},
                    ]}
                    yAxis={[{
                        disableTicks: true,
                    }]}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
                <CustomLegend/>
            </Box>
        </>
    )
}

export default InputsInventoryAndSalesGraph;
import {
    faShoppingCart,
    faTruck,
    faTableColumns,
    faCircleDollarToSlot
} from "@fortawesome/free-solid-svg-icons";
import {IMenuItem} from "../../../utils/MainMenu";

interface MainMenuProps {
    activePage: string;
}

export const InputsInnerMenu = ({activePage}: MainMenuProps): IMenuItem[] => [
    {
        label: "Overview",
        path: '/inputs/dashboard',
        isActive: activePage.toLowerCase().split("/").includes('dashboard'),
        icon: faTableColumns
    },
    {
        label: "Input Inventory",
        path: '/inputs/stock',
        isActive: activePage.toLowerCase().split("/").includes('stock'),
        icon: faShoppingCart
    },
    {
        label: "Input Sales",
        path: '/inputs/sales',
        isActive: activePage.toLowerCase().split("/").includes('sales'),
        icon: faCircleDollarToSlot
    },
    {
        label: "Input Movements",
        path: '/inputs/movements',
        isActive: activePage.toLowerCase().split("/").includes('movements'),
        icon: faTruck
    }
]
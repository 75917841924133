import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React from "react";
import {IContactPerson, IProcess} from "../interfaces/IHub";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import {faPhone, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditContactPersonForm from "./modals/EditContactPersonForm";
import DeleteContactPersonForm from "./modals/DeleteContactPersonForm";

type Props = {
    close: () => void,
    show: boolean,
    contactPerson: IContactPerson
}

const ContactPersonDetails = ({close, show, contactPerson}: Props) => {

    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-contact-person",
            dataToggle: "modal"
        },
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-contact-person",
            dataToggle: "modal",
            deleteIcon: true
        }];

    return (
        <>
            <XSidebarWrapper title={"Contact Person Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{contactPerson?.name.split(' ').map(name => name[0]).join('')}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{contactPerson?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} className="me-2"/> {`${contactPerson?.phoneNumber}`}
                                </span>
                            </div>

                            <div className="action-buttons">

                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash}/>}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">District</strong><br/>
                            <span>{contactPerson?.district || '-'}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Sub-county</strong><br/>
                            <span>{contactPerson?.subCounty || '-'}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Parish</strong><br/>
                            <span>{contactPerson?.parish || '-'}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Village</strong><br/>
                            <span>{contactPerson?.village || '-'}</span>
                        </div>
                        {contactPerson?.email &&
                            <div className="col-lg-6 col-12 mb-4">
                                <strong className="small fw-semibold">Email</strong><br/>
                                <span>{contactPerson?.email || '-'}</span>
                            </div>
                        }
                    </div>
                </div>

            </XSidebarWrapper>

            <EditContactPersonForm initialValues={contactPerson}/>
            <DeleteContactPersonForm item={contactPerson}/>

        </>
    );
}

export default ContactPersonDetails;
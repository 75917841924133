import {IBuyer} from "../../../app/modules/inventory/interfaces/IBuyer";

export const BUYERS_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_BUYERS: "FETCH_BUYERS",
    POST_BUYER: "POST_BATCH",
    UPDATE_BUYER: "UPDATE_BATCH",
    LOADING_BUYERS: "LOADING_BUYERS",
    GET_BATCH: "GET_BATCH",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
}

export interface IBuyerState {
    loading: boolean
    data: IBuyer[]
    filter: {
        show: boolean,
        data: IBuyer[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IBuyerState = {
    loading: false,
    data: [],
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function BUYERSReducer(state = initialState, action: any) {
    switch (action.type) {

        case BUYERS_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case BUYERS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case BUYERS_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case BUYERS_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case BUYERS_CONSTANTS.FETCH_BUYERS: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        case BUYERS_CONSTANTS.POST_BUYER: {
            return {...state, data: [action.payload, ...state.data]}
        }

        case BUYERS_CONSTANTS.UPDATE_BUYER: {
            return {
                ...state,
                data: state.data.map(buyer => buyer.id === action.payload.id ? action.payload : buyer)
            }
        }

        case BUYERS_CONSTANTS.LOADING_BUYERS: {
            return {...state, loading: action.payload}
        }

        case BUYERS_CONSTANTS.GET_BATCH: {
            return {...state, requestDetails: action.payload}
        }

        case BUYERS_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}

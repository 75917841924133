import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {IBatch} from "../interfaces/IBatch";
import {IHub} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";
import {remoteRoutes} from "../../../../data/constants";
import {useAuth} from "../../auth";
import {IBuyer} from "../interfaces/IBuyer";
import {buildUrl} from "../../../../utils/queryBuilder";
import {get, post, put} from "../../../../utils/ajax";
import {IApiResponse} from "../../../interfaces/IApiResponse";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {HUBS_CONSTANTS} from "../../../../data/redux/settings/hubsReducer";
import {BUYERS_CONSTANTS} from "../../../../data/redux/inventory/buyersReducer";
import {IBatchSale} from "../interfaces/IBatchSale";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {SellBatchSchema} from "../schemas/SellBatchSchema";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XAutoComplete} from "../../../components/shared/forms/XAutoComplete";

type Props = {
    initialValues: IBatch | null
}

const SellBatchForm = ({initialValues}: Props) => {
    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const hub: IHub | any = authService.getHub()
    const [buyers, setBuyers] = useState<IBuyer[]>([]);

    const [modalKey, setModalKey] = useState(Date.now());

    const buyerOptions = buyers.map((buyer: IBuyer) => ({
        label: buyer.name,
        id: buyer.id ?? ''
    }));

    const handleFetchBuyers = () => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            const params = {hubId};
            const url = buildUrl(remoteRoutes.inventoryService, '/buyers', params);
            get(url,
                (response: IApiResponse) => {
                    const {data} = response;
                    setBuyers(data);
                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                () => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: BUYERS_CONSTANTS.STOP_FETCH});
                });
        }
    };

    const batchSale = {
        hubId: initialValues?.hubId,
        date: initialValues?.date,
        batchId: initialValues?.id,
        buyerId: '',
        collectionCentreId: initialValues?.currentCollectionCentreId,
        collectionCentreName: initialValues?.currentCollectionCentreName,
        quantity: initialValues?.quantity,
        unitId: initialValues?.unitId,
        unitName: initialValues?.unitName
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.inventoryService, `/sales`);
        const data: IBatchSale = {
            id: values.id,
            hubId: values.hubId,
            date: values.date,
            batchId: values.batchId,
            buyerId: values.buyerId.id,
            collectionCentreId: values.collectionCentreId,
            collectionCentreName: values.collectionCentreName,
            quantity: values.quantity,
            unitId: values.unitId,
            unitName: values.unitName,
        }

        post(url, data, () => {

            CloseModal("sell-batch", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({type: BUYERS_CONSTANTS.POST_BUYER, payload: data});
            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };


    useEffect(() => {
        handleFetchBuyers()
    }, []);


    return (
        <Formik
            initialValues={batchSale}
            enableReinitialize={true}
            validationSchema={SellBatchSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="sell-batch"
                    title="Select Buyer"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={false}
                                component={XAutoComplete}
                                options={buyerOptions}
                                label="Select Buyer"
                                name="buyerId"
                                value={values.buyerId}
                            />
                        </div>

                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default SellBatchForm;
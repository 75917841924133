import {SettingsPageHeader} from "../../../components/shared/SettingsPageHeader";
import {TableLoader} from "../../../components/shared/loaders/TableLoader";
import React from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import {TableFilterLoader} from "../../../components/shared/loaders/TableFilterLoader";


export const RolesAndPermissionsPage = () => {
    return (
        <>
            <SettingsPageHeader title={"Settings > Roles and Permissions"}/>
            <TableWrapper>
                <TableFilterLoader/>
                <TableLoader/>
            </TableWrapper>
        </>
    );
}
import React, {useEffect, useState} from 'react';
import SideMenu from '../../../_theme/layout/components/SideMenu';
import {RolesAndPermissionsPage} from "./permission/_permissions";
import {HubCostCentersPage} from "./costCenters/HubCostCentersPage";
import {IHub, IProduct} from "./interfaces/IHub";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../auth";
import {authService} from "../../../data/oidc/AuthService";
import {HubProcessesPage} from "./processes/HubProcessesPage";
import {HubCollectionCentersPage} from "./collectionCenters/HubCollectionCentersPage";
import {HubContactPeoplePage} from "./contactPeople/HubContactPeoplePage";
import {Users} from "../onboarding/userManagement/Users";
import HubProductsPage from "./products/HubProductsPage";
import HubInputsPage from "./inputs/HubInputsPage";
import SystemProducts from "./systemProducts/SystemProducts";
import Units from "./units/Units";
import {IsGlobalAdmin, IsHubAdmin} from "../../hooks/roleChecker";
import {useParams} from "react-router-dom";

export const Settings = () => {

    const roles = authService.getRoles();
    const isGlobalAdmin = IsGlobalAdmin(roles);

    const defaultLanding = isGlobalAdmin ? 'System Products' : 'Collection Centers';


    const [activeItem, setActiveItem] = useState<string>(defaultLanding); // Default active item
    const auth = useAuth();
    const currentUser = auth.currentUser;
    const dispatch = useDispatch();

    const hub: IHub | any = authService.getHub();

    const urlParams = useParams()
    const hubId = urlParams?.hubId ?? currentUser?.hubId;

    const {products, inputs, costCentres, contactPersons, collectionCentres, processes} = hub;


    useEffect(() => {
    }, []);


    const renderContent = () => {
        switch (activeItem) {
            case 'Roles and Permission':
                return <RolesAndPermissionsPage/>
            case 'Hub Products':
                return <HubProductsPage products={products}/>
            case 'Inputs':
                return <HubInputsPage title={"Settings > Inputs"} hubId={hubId}/>
            case 'Cost Centers':
                return <HubCostCentersPage title={"Settings > Cost Centers"} hubId={hubId}/>
            case 'Processes':
                return <HubProcessesPage title={"Settings > Processes "} hubId={hubId}/>
            case 'Collection Centers':
                return <HubCollectionCentersPage title={"Settings > Collection Centres"} hubId={hubId}/>
            case 'Contact People':
                return <HubContactPeoplePage/>
            case 'System Products':
                return <SystemProducts/>
            case 'Units':
                return <Units/>
            default:
                return <div>Select a menu item</div>
        }
    };

    return (
        <>
            <div className="d-flex justify-content-start mt-3">
                <div className="me-4">
                    <SideMenu activeItem={activeItem} setActiveItem={setActiveItem}/>
                </div>
                <div className="card-body">
                    {renderContent()}
                </div>
            </div>
        </>
    );
};

import React, {useEffect, useState} from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import XTable, {ITableColumn} from "../../../components/shared/XTable";
import XTableFilter from "../../../components/shared/XTableFilter";

export const columns: ITableColumn[] = [
    {label: "Product Name", id: "name", textAlign: "text-start"},
    {label: "Date Last Restocked", isDate: true, id: "dateStocked", textAlign: "text-start"},
    {label: "Amount Sourced", isNumberFormat: true, id: "amountSourced", textAlign: "text-end"},
    {label: "", id: "unit1", textAlign: "text-start"},
    {label: "Amount Sold", isNumberFormat: true, id: "amountSold", textAlign: "text-end"},
    {label: "", id: "unit2", textAlign: "text-start"},
    {label: "Status", id: "status", textAlign: "text-start"},
]

const dummyData = [
    {
        id: 1,
        name: "Maize Seeds",
        dateStocked: "2024-07-10",
        amountSourced: 5000,
        unit1: "kg",
        amountSold: 3200,
        unit2: "kg",
        status: 8,
    },
    {
        id: 2,
        name: "Round Up",
        dateStocked: "2024-07-08",
        amountSourced: 2000,
        unit1: "liters",
        amountSold: 1500,
        unit2: "liters",
        status: 7,
    },
    {
        id: 3,
        name: "Gumboots",
        dateStocked: "2024-07-15",
        amountSourced: 300,
        unit1: "pairs",
        amountSold: 200,
        unit2: "pairs",
        status: 8,
    },
    {
        id: 4,
        name: "Bloom Buddy",
        dateStocked: "2024-07-05",
        amountSourced: 1200,
        unit1: "bottles",
        amountSold: 1100,
        unit2: "bottles",
        status: 9,
    },
    {
        id: 5,
        name: "Insecticide",
        dateStocked: "2024-07-12",
        amountSourced: 800,
        unit1: "liters",
        amountSold: 600,
        unit2: "liters",
        status: 8,
    },
]
const InventoryLedgerTable = () => {
    const [loading, setLoading] = useState(true);

    const handleSearch = () => {

    }

    useEffect(() => {
        setLoading(false);
        console.log(dummyData);
    }, []);

    return (
        <>
            <TableWrapper>
                <XTableFilter handleSearch={handleSearch} loading/>
                <XTable checked={false} data={dummyData} columns={columns} loading={loading}/>
                <a className={"text-end"} style={{color: "white"}} href="/inputs/stock">
                    <button className={"btn btn-primary btn-sm mb-2"}>
                        View all Inputs
                    </button>
                </a>
            </TableWrapper>
        </>
    )
}

export default InventoryLedgerTable
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faMapLocationDot,
    faPhone,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {IApiResponse, IPaginationMetadata} from "../../../../interfaces/IApiResponse";
import {IContactPerson} from "../../../settings/interfaces/IHub";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {remoteRoutes} from "../../../../../data/constants";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {get} from "../../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {IHeaderButtonProps} from "../../../../components/shared/SettingsPageHeader";
import {useAuth} from "../../../auth";
import AddContactPersonForm from "../../../settings/contactPeople/modals/AddContactPersonForm";

type Props = {
    contactPersons: IContactPerson[]
}

export const ContactPeople = ({contactPersons}: Props) => {

    const dispatch = useDispatch();

    const urlParams = useParams()
    const hubId = urlParams.hubId;

    const {currentUser} = useAuth();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([])
    const [filteredContactPersons, setFilteredContactPersons] = useState<IContactPerson[]>([]);

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 8,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const initialValues = {
        name: "",
        district: "",
        hubId: hubId ?? currentUser?.hubId,
        subCounty: "",
        parish: "",
        village: "",
        email: "",
        phoneNumber: ""
    };

    const [importing, setImporting] = useState<boolean>(false);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [selectedContactPerson, setSelectedContactPerson] = useState<IContactPerson>(initialValues)

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    const filterProcesses = () => {
        if (searchTerm) {
            setFilteredContactPersons(contactPersons.filter(process =>
                process.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredContactPersons(contactPersons);
        }
    }

    useEffect(() => {
        filterProcesses();
    }, [searchTerm, contactPersons]);

    useEffect(() => {
        setLoading(true);
        filterProcesses();
        handleFetchData(page, pagination.pageSize);
    }, [page]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            let params: any = {hubId, page, pageSize};

            const url = buildUrl(remoteRoutes.onboardingService, '/contact/persons', params);
            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_CONTACT_PEOPLE,
                        payload: data
                    });

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                    setLoading(false);
                });
        }
    }

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: IContactPerson) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
            } else {
                // Add the id if it's not in the array
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: IContactPerson) => {
        setDrawerVisible(!drawerVisible);
        setSelectedContactPerson(item)
    };

    const actionButtons: IHeaderButtonProps[] = [{
        label: "Add a Contact Person",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#new-contact-person",
        dataToggle: "modal"
    }];

    return (
        <>
            <div className="mt-3">
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <div className="page-title">Contact People</div>

                    {
                        actionButtons.map((button, index) => {
                            return (<button
                                data-bs-toggle={button.dataToggle}
                                data-bs-target={button.dataTarget}
                                className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                key={index}
                                onClick={button.onClick}>
                                {button.processing ? 'Please wait...' : button.label}
                            </button>)
                        })
                    }

                </div>
                <hr/>
                <div className="row mb-4">
                    {
                        contactPersons.map((contact, index) => {
                            return (
                                <div className="col-12 col-lg-4 col-md-6 mb-4" key={index}>
                                    <div className="card card-gray border-0">
                                        <div className="card-body">
                                            <div className="d-flex text-black align-items-center contact-card">
                                                <div className="flex-shrink-0 contact-icon text-center">
                                                    <FontAwesomeIcon icon={faUser} size="xl"/>
                                                </div>
                                                <div className="flex-grow-1 ms-3 align-items-center">
                                                    <h5 className="mb-2">{contact.name}</h5>
                                                    <div className="d-flex gap-2">
                                                        <FontAwesomeIcon icon={faPhone}/>
                                                        <p>{contact.phoneNumber}</p>
                                                    </div>

                                                    <div className="d-flex gap-2">
                                                        <FontAwesomeIcon icon={faEnvelope}/>
                                                        <p>{contact.email}</p>
                                                    </div>

                                                    <div className="d-flex gap-2">
                                                        <FontAwesomeIcon icon={faMapLocationDot}/>
                                                        <p>{contact.district && contact.subCounty
                                                            ? `${contact.district}, ${contact.subCounty}`
                                                            : 'Location not available'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <AddContactPersonForm hubId={hubId}/>

        </>
    )
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

type IProps = {
    width?: number
    title?: string
    children: any
    show?: boolean
    close?: () => void
}

export const XSidebarWrapper = ({children, title, show = false, width=25, close} : IProps) => {
    return (
        <div className={`details-pane w-${width} shadow-lg ${show ? 'visible' : 'visually-hidden'}`}>
            <div className="d-flex w-100 justify-content-between">
                <h6>
                    {title}
                </h6>
                <div>
                    <FontAwesomeIcon className={"clickable-row"} size={"1x"} onClick={close} icon={faClose} />
                </div>
            </div>
            <div className="w-100 py-3">
                {children}
            </div>
        </div>
    )
}
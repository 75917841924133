import {IHeaderButtonProps, SettingsPageHeader} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import {ICollectionCentre, ICostCentre, IProcess} from "../interfaces/IHub";
import {ITableColumn, XTable} from "../../../components/shared/XTable";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../auth";
import React, {useEffect, useState} from "react";
import {IApiResponse, IPaginationMetadata} from "../../../interfaces/IApiResponse";
import {IState} from "../../../../data/types";
import {ISettingsState, SETTINGS_CONSTANTS} from "../../../../data/redux/settings/settingsReducer";
import {remoteRoutes} from "../../../../data/constants";
import {buildUrl} from "../../../../utils/queryBuilder";
import {get} from "../../../../utils/ajax";
import {toast} from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CollectionCentreDetails from "./CollectionCentreDetails";
import CreateCollectionCentre from "./modals/CreateCollectionCentre";

interface IProps {
    hubId?: string;
    title?: string;
}

const columns: ITableColumn[] = [
    {
        label: 'Collection Center Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'District',
        id: 'district',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Village',
        id: 'village',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Latitude',
        id: 'latitude',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Longitude',
        id: 'longitude',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

export const HubCollectionCentersPage = ({title, hubId}: IProps) => {

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const {currentUser} = useAuth();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([])
    const [filteredCollectionCentres, setFilteredCollectionCentres] = useState<ICollectionCentre[]>([]);

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 5,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const {collectionCentres, loading}: ISettingsState = useSelector((state: IState) => state.settings);

    const initialValues = {
        name: '',
        district: '',
        hubId: currentUser?.hubId,
        subCounty: '',
        parish: '',
        village: '',
        latitude: 0,
        longitude: 0
    }

    const [importing, setImporting] = useState<boolean>(false);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [selectedCollectionCentre, setSelectedCollectionCentre] = useState<ICollectionCentre>(initialValues)

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    const filterCollectionCentres = () => {
        if (searchTerm) {

            const data: ICollectionCentre[] = collectionCentres;

            setFilteredCollectionCentres(data.filter(collectionCentre =>
                collectionCentre.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredCollectionCentres(collectionCentres);
        }
    }

    useEffect(() => {
        filterCollectionCentres()
    }, [searchTerm, collectionCentres]);

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            let params: any = {hubId, page, pageSize};

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.onboardingService, '/collection/centres', params);
            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_COLLECTION_CENTRES,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: SETTINGS_CONSTANTS.STOP_FETCH});
                    setLoading(false);
                });
        }
    }

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: ICollectionCentre) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                // Remove the id if it's already in the array
                newSelected.splice(index, 1);
            } else {
                // Add the id if it's not in the array
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: ICollectionCentre) => {
        setDrawerVisible(!drawerVisible);
        setSelectedCollectionCentre(item)
    };

    const actionButtons: IHeaderButtonProps[] = [{
        label: "New Collection Centre",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#new-collection-centre",
        dataToggle: "modal"
    }];

    return (
        <>
            {title && <SettingsPageHeader title={title}/>}
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                               className="form-control"
                               placeholder="Type to search..."/>
                        <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                    </div>

                    <div className="action-buttons">

                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button>

                        {showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    data={filteredCollectionCentres}
                    columns={columns}
                    loading={loading}/>

                <XPagination
                    dataLength={collectionCentres.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)}/>
            </TableWrapper>

            <CollectionCentreDetails close={() => setDrawerVisible(false)} show={drawerVisible}
                                     collectionCentre={selectedCollectionCentre}/>

            <CreateCollectionCentre hubId={hubId}/>

        </>
    );
}
import {
    ICategories,
    ICollectionCentre,
    IContactPerson,
    IHub,
    IProcess,
    IUnit
} from "../../../app/modules/settings/interfaces/IHub";
import {IInput} from "../../../app/modules/settings/inputs/interfaces/IInput";
import {IUser} from "../../../app/modules/onboarding/userManagement/interfaces/IUser";

export const SETTINGS_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_DATA: "FETCH_DATA",
    POST_DATA: "POST_DATA",
    POST_INPUTS: "POST_INPUTS",
    POST_PRODUCTS: "POST_PRODUCTS",
    POST_COST_CENTRES: "POST_COST_CENTRES",
    POST_PROCESSES: "POST_PROCESSES",
    POST_UNITS: "POST_UNITS",
    POST_COLLECTION_CENTRES: "POST_COLLECTION_CENTRES",
    POST_CONTACT_PEOPLE: "POST_CONTACT_PEOPLE",
    POST_USERS: "POST_USERS",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
    FETCH_INPUTS: "FETCH_INPUTS",
    FETCH_PRODUCTS: "FETCH_PRODUCTS",
    FETCH_COST_CENTRES: "FETCH_COST_CENTRES",
    FETCH_PROCESSES: "FETCH_PROCESSES",
    FETCH_COLLECTION_CENTRES: "FETCH_COLLECTION_CENTRES",
    FETCH_CONTACT_PEOPLE: "FETCH_CONTACT_PEOPLE",
    FETCH_UNITS: "FETCH_UNITS",
    FETCH_USERS: "FETCH_USERS",
    FETCH_CATEGORIES: "FETCH_CATEGORIES"
}

export interface ISettingsState {
    loading: boolean
    inputs: [],
    products: [],
    costCenters: [],
    processes: [],
    collectionCentres: [],
    contactPeople: [],
    users: [],
    units: [],
    categories: ICategories [],

    filter: {
        show: boolean,
        inputs: [],
        products: [],
        costCenters: [],
        processes: [],
        collectionCentres: [],
        contactPeople: [],
        users: [],
        units: [],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: ISettingsState = {
    loading: false,
    inputs: [],
    products: [],
    costCenters: [],
    processes: [],
    collectionCentres: [],
    contactPeople: [],
    users: [],
    units: [],
    categories: [],
    filter: {
        key: '',
        show: false,
        inputs: [],
        products: [],
        costCenters: [],
        processes: [],
        collectionCentres: [],
        contactPeople: [],
        users: [],
        units: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function settingsReducer(state = initialState, action: any) {
    switch (action.type) {

        case SETTINGS_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case SETTINGS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case SETTINGS_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case SETTINGS_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case SETTINGS_CONSTANTS.FETCH_INPUTS: {
            return {...state, loading: false, inputs: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_CATEGORIES: {
            return {...state, loading: false, categories: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_USERS: {
            return {...state, loading: false, users: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_UNITS: {
            return {...state, loading: false, units: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_PRODUCTS: {
            return {...state, loading: false, products: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_COST_CENTRES: {
            return {...state, loading: false, costCenters: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_PROCESSES: {
            return {...state, loading: false, processes: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_COLLECTION_CENTRES: {
            return {...state, loading: false, collectionCentres: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.FETCH_CONTACT_PEOPLE: {
            return {...state, loading: false, contactPeople: action.payload, reset: false};
        }

        case SETTINGS_CONSTANTS.POST_INPUTS: {
            const input: IInput = action.payload
            return {...state, inputs: [input, ...state.inputs]}
        }

        case SETTINGS_CONSTANTS.POST_UNITS: {
            const unit: IUnit = action.payload
            return {...state, units: [unit, ...state.units]}
        }

        case SETTINGS_CONSTANTS.POST_PROCESSES: {
            const processes: IProcess = action.payload
            return {...state, processes: [processes, ...state.processes]}
        }

        case SETTINGS_CONSTANTS.POST_USERS: {
            const users: IUser = action.payload
            return {...state, users: [users, ...state.users]}
        }

        case SETTINGS_CONSTANTS.POST_COLLECTION_CENTRES: {
            const collectionCentre: ICollectionCentre = action.payload
            return {...state, collectionCentres: [collectionCentre, ...state.collectionCentres]}
        }

        case SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE: {
            const contactPerson: IContactPerson = action.payload
            return {...state, contactPeople: [contactPerson, ...state.contactPeople]}
        }

        case SETTINGS_CONSTANTS.LOADING_DATA: {
            return {...state, loading: action.payload}
        }

        case SETTINGS_CONSTANTS.GET_DETAILS: {
            return {...state, requestDetails: action.payload}
        }

        case SETTINGS_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
import React from 'react';
import {IPaginationMetadata} from "../../interfaces/IApiResponse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

type IProps = {
    currentPage: number
    pagination: IPaginationMetadata
    dataLength: number
    setPage: (page: number) => void
};

const XPagination = ({currentPage, pagination, dataLength, setPage} : IProps) => {
    return (
        <>
            {dataLength > 0 && <div className="d-flex mb-3 w-100 align-items-center justify-content-between">
                <div className="text-muted">
                    Showing {((pagination.currentPage - 1) * pagination.pageSize) + 1} - {((pagination.currentPage - 1) * pagination.pageSize) + dataLength} of {pagination.totalItems}
                </div>

                <nav aria-label="...">
                    <ul className="pagination pagination-sm">
                        {pagination.hasPreviousPage && <li className="page-item">
                            <button className="page-link text-black" onClick={() => setPage(currentPage - 1)}>
                                <FontAwesomeIcon icon={faArrowLeft}/> PREV
                            </button>
                        </li>}
                        {pagination.hasNextPage && <li className="page-item">
                            <button className="page-link text-black" onClick={() => setPage(currentPage + 1)}>
                                NEXT <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                        </li>}
                    </ul>
                </nav>
            </div>}
        </>
    );
}

export default XPagination;
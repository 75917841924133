import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

const InventoryStockProgress = () => {
    const inventoryStockData = [
        {
            label: "Sun Flower",
            percentage: 31.4,
            color: "#AA0050",
            inStock: "32 MT",
            sourced: "102 MT"
        },
        {
            label: "Coffee",
            percentage: 18,
            color: "#5F1ED9",
            inStock: "18 MT",
            sourced: "100 MT"
        },
        {
            label: "Chia",
            percentage: 75,
            color: "#34C38F",
            inStock: "900 MT",
            sourced: "1200 MT"
        },
        {
            label: "Maize",
            percentage: 50,
            color: "#DF901F",
            inStock: "60 MT",
            sourced: "120 MT"
        }

    ];

    return (
        <>
            {inventoryStockData.map((item, index) => (
                <div key={index} className="mt-3">
                    <div className="row justify-content-between">
                        <div className="col d-flex align-items-center">
                            <div className="progress-dot"
                                 style={{backgroundColor: item.color}}>
                            </div>
                            <div className="progress-label">
                                {item.label}
                            </div>
                        </div>

                        <div className="col-3 align-items-center text-end progress-percentage"
                             style={{color: "#616162"}}>
                            {item.percentage}%
                        </div>
                    </div>

                    <ProgressBar
                        completed={item.percentage}
                        customLabel=" "
                        bgColor={item.color}
                        labelColor="transparent"
                        height="12px"
                    />

                    <div className="row justify-content-between progress-percentage">
                        <div className="col-5"
                             style={{color: item.color}}>
                            In-stock: <b>{item.inStock}</b>
                        </div>
                        <div className="col-5 text-end"
                             style={{color: "#616162"}}>
                            Sourced: <b>{item.sourced}</b>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}

export default InventoryStockProgress;
export const CloseModal = (modalId: string, callback?: () => void) => {
    let mainModal: HTMLElement | null = document.getElementById(modalId);

    if (mainModal) {
        mainModal.classList.remove('show');
        mainModal.style.display = 'none';
    }

    // Remove the backdrop if it exists
    let modalWrap = document.getElementsByClassName('modal-backdrop');
    if (modalWrap.length > 0) {
        modalWrap[0].remove();
    }

    // Restore page scroll when the modal is closed
    document.body.style.overflow = 'auto';

    if (callback) callback()
}

export const OpenModal = (modalId: string) => {
    let mainModal: HTMLElement | null = document.getElementById(modalId);

    if (mainModal) {
        // Reset form fields
        const formElements = mainModal.querySelectorAll('input, textarea, select');
        formElements.forEach((element: Element) => {
            if ((element as HTMLInputElement).type === 'checkbox' || (element as HTMLInputElement).type === 'radio') {
                (element as HTMLInputElement).checked = false;
            } else {
                (element as HTMLInputElement).value = '';
            }
        });

        mainModal.classList.add('show');
        mainModal.style.display = 'block';

        // Always add the backdrop element
        let backdrop = document.querySelector('.modal-backdrop');
        if (!backdrop) {
            backdrop = document.createElement('div');
            backdrop.className = 'modal-backdrop fade show';
            document.body.appendChild(backdrop);
        }

        // Ensure page scroll is enabled
        document.body.style.overflow = 'auto';

    }
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faShoppingCart, faTruck} from "@fortawesome/free-solid-svg-icons";

export const printBatchStatus = (value?: number | undefined) => {
    switch (value) {
        case 1: return <span className="badge rounded-pill bg-secondary">Batched <FontAwesomeIcon icon={faShoppingCart} /></span>
        case 2: return <span className="badge rounded-pill bg-secondary-subtle">In Transit <FontAwesomeIcon icon={faTruck} /></span>
        case 3: return <span className="badge rounded-pill bg-info">Delivered</span>
        case 4: return <span className="badge rounded-pill bg-warning">Processing</span>
        case 5: return <span className="badge rounded-pill bg-success">Processed</span>
        case 6: return <span className="badge rounded-pill bg-black">Sold <FontAwesomeIcon icon={faCheck} /></span>
        case 7:
            return <span className="badge rounded-pill bg-warning">Low Stock</span>
        case 8:
            return <span className="badge rounded-pill bg-success">In Stock</span>
        case 9:
            return <span className="badge rounded-pill bg-danger">Out of Stock</span>

        default: return <span className="badge rounded-pill bg-secondary">Batched <FontAwesomeIcon icon={faShoppingCart} /></span>
    }
}
import {useDispatch} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {districts} from "../../../../../data/districts";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../../auth";
import {HUBS_CONSTANTS} from "../../../../../data/redux/settings/hubsReducer";
import {HubSchema, IHubDto} from "../interfaces/IHubDto";
import {IHub, IInputCategory} from "../../../settings/interfaces/IHub";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {InputAdornment} from "@mui/material";

const CreateClientForm = () => {

    const dispatch = useDispatch();
    const {currentUser} = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: '',
        email: '',
        phoneNumber: '',
        district: {label: '', id: ''},
        parentHubId: '',
        subCounty: '',
        village: '',
        postalAddress: '',
        minimumAggregationUnit: ''
    }

    const [hubs, setHubs] = useState<IHub[]>([]);

    const hubOptions = hubs?.map((hub: IHub) => ({
        label: hub.name,
        id: hub.id
    }))

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, '/hubs')
        const data: IHubDto = {
            name: values.name,
            email: values.email,
            phoneNumber: `+256${values.phoneNumber}`,
            district: values.district?.id,
            parentHubId: values.parentHubId.id,
            subCounty: values.subCounty,
            village: values.village,
            postalAddress: values.postalAddress,
            minimumAggregationUnit: values.minimumAggregationUnit,
        }

        post(url, data, (response) => {

            CloseModal("new-client", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: HUBS_CONSTANTS.POST_DATA,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)

            setModalKey(Date.now()); // Reset modal key to force re-render
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
        handleFetchHubs(1, 1000)
    }, []);

    const handleFetchHubs = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            let params: any = {hubId, page, pageSize};

            const url = buildUrl(remoteRoutes.onboardingService, '/hubs', params);
            get(url,
                (response: IApiResponse) => {

                    const {data, paginationMetadata} = response;
                    setHubs(data)

                    // toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: HUBS_CONSTANTS.STOP_FETCH});
                });
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const value = event.target.value;
        if (value.length <= 9) {
            setFieldValue("phoneNumber", value);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={HubSchema}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting, setFieldValue}) => {
                return <ModalWrapper
                    id="new-client"
                    title="Add a new Client"
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Client Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Minimum Aggregation Unit (MAU)"
                                name="minimumAggregationUnit"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Postal Address"
                                name="postalAddress"
                            />
                        </div>


                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Phone Number"
                                name="phoneNumber"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">+256</InputAdornment>,
                                    maxLength: 9,
                                    onKeyPress: handleKeyPress,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, setFieldValue)
                                }}
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label="District"
                                name="district"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Parish"
                                name="parish"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Village"
                                name="village"
                            />
                        </div>
                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default CreateClientForm;
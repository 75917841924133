import {useDispatch, useSelector} from "react-redux";
import {Field, Formik, useField} from "formik";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ICollectionCentre, IUnit} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {get, post, put} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {useAuth} from "../../../auth";
import {UnitSchema} from "../interfaces/UnitSchema";
import {IApiResponse} from "../../../../interfaces/IApiResponse";
import {IState} from "../../../../../data/types";

type Props = {
    id: string
}

const EditUnit = ({id}: Props) => {

    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const units: IUnit[] = useSelector((state: IState) => state.settings.units);

    const [modalKey, setModalKey] = useState(Date.now());

    const initial: IUnit = {
        id: '',
        name: '',
        displayName: ''
    }

    const unit = units.find(u => u.id == id) ?? initial;

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/units/${id}`)
        const data: IUnit = {
            id: id,
            name: values.name,
            displayName: values.displayName
        }

        put(url, data, (response) => {

            CloseModal("edit-unit", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_UNITS,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
    }, []);


    return (
        <Formik
            initialValues={unit}
            validationSchema={UnitSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="edit-unit"
                    title="Edit Unit of Measurement"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Display Name"
                                name="displayName"
                            />
                        </div>
                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default EditUnit;
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {Field, Formik} from "formik";
import {InputAdornment} from "@mui/material";
import {IOption, XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {IInputDistribution, InputDistributionSchema} from "../interfaces/IInputDistribution";
import {post} from "../../../../utils/ajax";
import {buildUrl} from "../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../data/constants";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import moment from "moment";
import {useAuth} from "../../auth";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {INPUTS_DISTRIBUTION_CONSTANTS} from "../../../../data/redux/inputs/inputsReducer";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";


export const DistributeInputsForm = () => {

    const dispatch = useDispatch()
    const {currentUser} = useAuth()
    const [unitsOfMeasure, setUnitsOfMeasure] = useState<IOption[]>([])
    const [inputs, setInputs] = useState<IOption[]>([])
    const [agents, setAgents] = useState<IOption[]>([])
    const [farmers, setFarmers] = useState<IOption[]>([])

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        input: null,
        farmer: null,
        agent: null,
        unit: null,
        amountDue: 0,
        amountPaid: 0,
        quantity: 0
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.inputsService, '/inputs/sales')
        const data: IInputDistribution = {
            hubId: currentUser?.hubId,
            date: moment().toISOString(),

            farmerId: values.farmer?.id,
            farmer: values.agent?.label,
            // farmerGroupId: '',
            agent: values.agent?.label,
            input: values.input?.label,
            inputId: values.input?.id,
            // collectionCentreId:'',
            // collectionCentre: '',
            quantity: Number(values.quantity),
            // plantingYear: '',
            // plantingSeason: '',
            unit: values.unit?.id,
            unitPrice: Number(values.amountDue) / Number(values.quantity),
            amountDue: Number(values.amountDue),
            amountPaid: Number(values.amountPaid),
            balanceDue: Number(values.amountDue) - Number(values.amountPaid),
            // commissionAmountPerUnit: '',
        }

        post(url, data, (response) => {

            CloseModal("distribute-inputs", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.POST_DATA,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    useEffect(() => {
        setInputs([
            {label: "Fertilizers", id: "1421d652-7d2a-46ef-8935-8739dfe42214"},
            {label: "Seeds", id: "09922b34-f324-4b0f-94d7-100485144b9c"}
        ])
        setAgents([
            {label: "Wilson Kiggundu", id: "399fbcbc-003d-402f-844c-5eca4ffb0d97"},
            {label: "Deborah Kiggundu", id: "09922b34-f324-4b0f-94d7-100485144b9c"},
            {label: "Maggie Namaganda", id: "4b92aee8-8f91-4416-804b-8936bbf31239"}
        ])
        setFarmers([
            {label: "Eliana Aretha", id: "399fbcbc-003d-402f-844c-5eca4ffb0d97"},
            {label: "Ezekiel Israel", id: "09922b34-f324-4b0f-94d7-100485144b9c"},
        ])
        setUnitsOfMeasure([
            {label: "Kg", id: "kg"},
            {label: "Dozens", id: "dozens"},
            {label: "Boxes", id: "boxes"}
        ])
    }, [])

    return (


        <Formik
            initialValues={initialValues}
            validationSchema={InputDistributionSchema}
            onSubmit={handleSubmit}>
            {({handleSubmit, isSubmitting, values, setFieldValue}) => {

                return <ModalWrapper
                    id="distribute-inputs"
                    title={"Distribute Inputs"}
                    size="lg"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={farmers}
                                label="Farmer"
                                name="farmer"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={agents}
                                label="Agent"
                                name="agent"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={inputs}
                                label="Input"
                                name="input"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={unitsOfMeasure}
                                label="Unit of Measure"
                                name="unit"
                            />
                        </div>
                        <div className="col-lg-3">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Quantity"
                                name="quantity"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                disabled={isSubmitting}
                                as={XTextField}
                                type={"number"}
                                label="Total Amount"
                                name="amountDue"
                                inputProps={{
                                    endAdornment: <InputAdornment position="end">UGX</InputAdornment>
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Amount Paid"
                                name="amountPaid"
                                inputProps={{
                                    endAdornment: <InputAdornment position="end">UGX</InputAdornment>
                                }}
                            />
                        </div>
                    </div>
                </ModalWrapper>

            }}
        </Formik>


    )
}


import {XSidebarWrapper} from "../../components/shared/XSidebarWrapper";
import {printNaturalDateShort} from "../../../utils/dateHelpers";
import React from "react";

type Props = {
    close: () => void,
    show: boolean,
    selectedRow: any
}

export function InputDetails({close, show, selectedRow} : Props) {
    return <XSidebarWrapper close={close} show={show}>
        <div className="d-flex justify-content-start w-100 align-items-center">
            <div className="py-3 px-3 rounded-1 bg-primary">
                <h2>{selectedRow?.input && selectedRow?.input[0].toUpperCase()}</h2>
            </div>
            <div className="px-3">
                <h5>{selectedRow?.input}</h5>
                <div
                    className="small">UGX {Number(selectedRow?.amountDue).toLocaleString()}
                </div>
                <div className="fs-6 small">
                            <span className="badge text-bg-info">
                                {selectedRow?.quantity + " " + selectedRow?.unit}
                            </span>
                </div>
            </div>
        </div>
        <hr/>
        <div className="p-3 bg-gray rounded-3 mb-3">
            <div className="row">
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Date of distribution</strong><br/>
                    <span>{printNaturalDateShort(selectedRow?.date)}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Category</strong><br/>
                    <span>{"-"}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Unit Price</strong><br/>
                    <span>UGX {Number(selectedRow?.unitPrice).toLocaleString()}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Collection Center</strong><br/>
                    <span>{selectedRow?.collectionCentre || "-"}</span>
                </div>
            </div>
        </div>

        <div className="p-3 bg-gray rounded-3 mb-3">
            <div className="row">
                <div className="col-lg-6 col-12 mb-4">
                    <span className="small fw-semibold">Farmer</span><br/>
                    <span>{selectedRow?.farmer || "-"}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Contact</strong><br/>
                    <span>{"-"}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Group</strong><br/>
                    <span>{selectedRow?.farmerGroupId || "-"}</span>
                </div>
                <div className="col-lg-6 col-12 mb-4">
                    <strong className="small fw-semibold">Agent</strong><br/>
                    <span>{selectedRow?.agent || "-"}</span>
                </div>
            </div>
        </div>
    </XSidebarWrapper>;
}
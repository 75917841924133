import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-hot-toast";
import {faEye} from "@fortawesome/free-solid-svg-icons";

import {IButtonProps, PageHeader} from "../../components/shared/PageHeader";
import {PageHeaderLoader} from "../../components/shared/loaders/PageHeaderLoader";
import TableWrapper from "../../components/shared/TableWrapper";
import XTableFilter from "../../components/shared/XTableFilter";
import XTable, {IRowAction, ITableColumn} from "../../components/shared/XTable";
import XPagination from "../../components/shared/XPagination";
import {IApiResponse, IPaginationMetadata} from "../../interfaces/IApiResponse";
import {remoteRoutes} from "../../../data/constants";
import {buildUrl} from "../../../utils/queryBuilder";
import {get} from "../../../utils/ajax";
import {FARMER_PROFILES_CONSTANTS} from "../../../data/redux/farmers/profilesReducer";
import {overrideToastDefaults, toastMessages} from "../../../data/toastDefaults";
import {useAuth} from "../auth";
import {IInputsDistributionState, INPUTS_DISTRIBUTION_CONSTANTS} from "../../../data/redux/inputs/inputsReducer";
import {IState} from "../../../data/types";
import {DistributeInputsForm} from "./modals/DistributeInputsForm";
import {IInputDistribution} from "./interfaces/IInputDistribution";
import {InputDetails} from "./InputDetails";

const columns: ITableColumn[] = [
    {label: "Date", isDate: true, id: "date", textAlign: "text-start"},
    {label: "Farmer", id: "farmer", textAlign: "text-start"},
    {label: "Input", id: "input", textAlign: "text-start"},
    // { label: "Location", id: "collectionCentre", textAlign: "text-start" },
    {label: "Quantity", isNumberFormat: true, id: "quantity", textAlign: "text-end"},
    {label: "", isNumberFormat: false, id: "unit", textAlign: "text-start"},
    {label: "Total Amount", isNumberFormat: true, id: "amountDue", textAlign: "text-end"},
    {label: "Amount Paid", isNumberFormat: true, id: "amountPaid", textAlign: "text-end"},
    {label: "Balance", isNumberFormat: true, id: "balanceDue", textAlign: "text-end"}
];

export const InputDistributions = () => {
    const dispatch = useDispatch();

    const {currentUser} = useAuth();

    const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const {data, loading}: IInputsDistributionState = useSelector((state: IState) => state.inputs);

    const actionButtons: IButtonProps[] = [
        {
            label: 'Distribute Inputs',
            cssClass: 'btn btn-primary btn-lg',
            dataTarget: "#distribute-inputs",
            dataToggle: "modal"
        }
    ];

    const rowActions: IRowAction[] = [
        {label: "Details", icon: faEye, onClick: (row: any) => viewDetails(row)}
    ];

    const viewDetails = (row: any) => {
        setSelectedRow(row);
        setDrawerVisible(true);
    };

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined);
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value);
        }
        setPage(1);
    };

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.profiles) {
            const hubId = currentUser?.hubId;
            let params: any = {hubId, page, pageSize};

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.inputsService, '/inputs/sales', params);
            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setPagination(paginationMetadata);

                    dispatch({
                        type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: FARMER_PROFILES_CONSTANTS.STOP_FETCH});
                    setLoading(false);
                });
        }
    };

    const handleRowClick = (item: any) => {
        setDrawerVisible(true)
        setSelectedRow(item)
    }

    return (
        <>
            {loading && <PageHeaderLoader/>}
            {!loading && <PageHeader title={"Inputs"} actionButtons={actionButtons}/>}

            <TableWrapper>
                <XTableFilter loading={loading} handleSearch={handleSearch}/>
                <XTable selected={[]} onRowClick={(item) => handleRowClick(item)} rowActions={rowActions}
                        data={data}
                        columns={columns} loading={loading}/>
                <XPagination currentPage={page} pagination={pagination} dataLength={data.length} setPage={setPage}/>
            </TableWrapper>

            <DistributeInputsForm/>
            <InputDetails close={() => setDrawerVisible(false)} show={drawerVisible} selectedRow={selectedRow}/>
        </>
    );
};

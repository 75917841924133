import {useDispatch} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import {CollectionCentreSchema} from "../interfaces/CollectionCentreSchema";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import {ICollectionCentre} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {put} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {districts} from "../../../../../data/districts";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";
import {useAuth} from "../../../auth";

interface Props {
    initialValues: ICollectionCentre
}

const EditCollectionCentreForm = ({initialValues}: Props) => {

    const dispatch = useDispatch();

    const handleSubmit = (values: any, resetForm: any) => {


        const url = buildUrl(remoteRoutes.onboardingService, `/collection/centres/${values.id}`)
        const data: ICollectionCentre = {
            id: values.id,
            name: values.name,
            district: values.district.label,
            hubId: values.hubId,
            subCounty: values.subCounty,
            parish: values.parish,
            village: values.village,
            latitude: values.latitude,
            longitude: values.longitude
        }

        put(url, data, (response) => {

            CloseModal("edit-collection-centre")

            dispatch({
                type: SETTINGS_CONSTANTS.POST_DATA,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district
                } : null,
                latitude: initialValues.latitude !== null && initialValues.latitude !== undefined ? initialValues.latitude : '',
                longitude: initialValues.longitude !== null && initialValues.longitude !== undefined ? initialValues.longitude : '',
            }}
            enableReinitialize={true}
            validationSchema={CollectionCentreSchema}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="edit-collection-centre"
                    title="Edit Collection Centre"
                    size="md"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label="District"
                                name="district"
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Parish"
                                name="parish"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Village"
                                name="village"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Latitude"
                                name="latitude"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Longitude"
                                name="longitude"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}

export default EditCollectionCentreForm;
import React, {useEffect, useState} from 'react';
import XTable, {ITableColumn} from "../../../components/shared/XTable";
import {MockInputs} from "../../../../data/mock/mockInputs";
import {remoteRoutes} from "../../../../data/constants";
import {buildUrl} from "../../../../utils/queryBuilder";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {get, search} from "../../../../utils/ajax";
import {INPUTS_DISTRIBUTION_CONSTANTS} from "../../../../data/redux/inputs/inputsReducer";
import TableWrapper from "../../../components/shared/TableWrapper";
import Map from "../../../components/shared/Map";
import {useAuth} from "../../auth";

interface IProps {
    plots: any[]
}

const Plots = (props: IProps) => {

    const dispatch = useDispatch()
    const {plots} = props

    const params = useParams()
    const farmerId = params.farmerId
    const {currentUser} = useAuth()

    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const [polygons, setPolygons] = useState<any>([])

    const columns: ITableColumn[] = [
        {label: 'Plot Number', id: 'plotNumber'},
        {label: 'Year', id: 'year'},
        {label: 'Season', id: 'season'},
        {label: 'Ownership', id: 'landOwnership'},
        {label: 'Production', id: 'productionType'},
        {label: 'No of trees', id: 'numberOfTrees'},
        {label: 'Productive trees', id: 'numberOfProductiveTrees'},
    ]

    useEffect(() => {
        // handleFetchData()

        const polygonArray = plots.map(plot =>
            plot.polygonCoordinates.map((coord: any) => ({
                lat: coord.latitude,
                lng: coord.longitude
            }))
        );

        setPolygons(polygonArray)

    }, [plots])

    // const handleFetchData = () => {
    //     if (remoteRoutes.profiles) {
    //
    //         const hubId = currentUser?.hubId
    //         const url = buildUrl(remoteRoutes.profiles, `/plots`)
    //
    //         search(url, {farmerId, hubId},
    //             (data) => {
    //
    //                 setData(data)
    //                 setLoading(false)
    //
    //             },
    //             (error) => {
    //                 toast.error(toastMessages.default.fail, overrideToastDefaults)
    //             },
    //             () => dispatch({type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH}))
    //     }
    // }

    return (
        <>
            <div className="mb-3 rounded-5">
                <Map width={'100%'} height={'800px'} zoom={7} polygons={polygons}/>
            </div>
            {/*<TableWrapper>*/}
            {/*    <XTable data={data} columns={columns} loading={loading}/>*/}
            {/*</TableWrapper>*/}
        </>
    );
};

export default Plots;
import * as Yup from 'yup'

export interface IInputDistribution {
    id?: string
    hubId?: string | null
    dateCreated?: string
    dateUpdated?: string
    date?: string | null
    farmerId?: string | null
    farmer?: string | null
    farmerGroupId?: string
    agent?: string | null
    input?: string | null
    inputId?: string | null
    unit?: string | null
    collectionCentreId?: string
    collectionCentre?: string
    quantity?: number | null
    plantingYear?: string
    plantingSeason?: string
    unitOfMeasurement?: string
    unitPrice?: number | null
    amountDue?: number | null
    amountPaid?: number | null
    balanceDue?: number | null
    commissionAmountPerUnit?: string
}

export const InputDistributionSchema = Yup.object({
    farmer: Yup.object().nullable().required('Required'),
    agent: Yup.object().nullable().required('Required'),
    input: Yup.object().nullable().required('Required'),
    unit: Yup.object().nullable().required('Required'),
    quantity: Yup.number().required('Required').positive('Quantity must be positive').integer('Quantity must be an integer'),
    amountDue: Yup.number().required('Required').positive('Total Amount must be positive'),
    amountPaid: Yup.number().required('Required').min(0, 'Amount Paid cannot be negative')
})
import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCog, faSignOut, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {Logout, useAuth} from "../../../app/modules/auth";
import {AppBar, Toolbar, Typography} from "@mui/material";
import {drawerWidth} from "./MainMenuDrawer";
import {useNavigate} from "react-router-dom";
import {getIdToken} from "../../../utils/ajax";
import {remoteRoutes} from "../../../data/constants";

const Header = () => {

    const {currentUser, logout} = useAuth()

    const navigate = useNavigate()
    const token = getIdToken();
    const logoutUrl = `${remoteRoutes.authServer}/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${window.location.origin}`;

    const handleLogout = () => {
        logout()
        // const logoutUrl = `https://test.accounts.asigmagroup.com/Account/Logout`

        const logoutUrl = `${remoteRoutes.authServer}/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${window.location.origin}`;
        console.log(logoutUrl)
        window.location.href = logoutUrl
    }

    return (
        <AppBar
            elevation={0}
            className="bg-white text-black"
            position="fixed"
            style={{zIndex: 1}}
            sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}
        >
            <Toolbar>
                <Typography className="w-100" variant="inherit" noWrap component="div">
                    <div className="d-flex w-100 justify-content-end align-items-center">
                        <div className="d-flex align-items-center flex-row">
                            {/*<div className="px-2">*/}
                            {/*    <FontAwesomeIcon icon={faCog} size="1x"/>*/}
                            {/*</div>*/}
                            {/*<div className="px-2">*/}
                            {/*    <FontAwesomeIcon icon={faBell} size="1x"/>*/}
                            {/*</div>*/}
                            {currentUser ? (
                                <div className="px-2">
                                    <a href={''}
                                       id={`user-profile`}
                                       role="button"
                                       data-bs-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                       className="dropdown-toggle text-black">
                                        {currentUser.given_name} {currentUser.family_name}
                                    </a>

                                    <ul className="dropdown-menu shadow border-0" aria-labelledby={`user-profile`}>
                                        <Logout/>
                                    </ul>

                                </div>
                            ) : <></>}

                            <div className="px-2">
                                <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                            </div>
                        </div>
                    </div>
                </Typography>
            </Toolbar>
        </AppBar>


    );
};

export default Header;
export const IsGlobalAdmin = (roles: string[] | undefined) => {
    if (roles) {
        return roles.includes("Global Administrator");
    }
}

export const IsHubAdmin = (roles: string[] | undefined) => {
    if (roles) {
        return roles.includes("Hub Administrator");
    }
}

export const IsAgent = (roles: string[] | undefined) => {
    if (roles) {
        return roles.includes("Agent");
    }
}
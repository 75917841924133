import React from "react";
import {Box} from "@mui/material";
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Amount in Credit",
            color: "#1C583880",
        },
        {
            label: "Received Cash",
            color: "#1F5A2D",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                         sx={{backgroundColor: item.color}}
                    />
                    <span style={{color: '#848484'}}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    )
};

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000_000) {
        return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)}K`;
    } else {
        return `${value}`;
    }
};

const InventorySalesGraph = () => {

    return (
        <>
            <Box>
                <BarChart
                    series={[
                        {
                            data: [400000, 300000, 1200000, 500000],
                            stack: 'A',
                            label: 'Received Cash',
                            color: '#1F5A2D',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                        {
                            data: [340000, 490000, 1430000, 63300],
                            stack: 'A',
                            label: 'Amount in Credit',
                            color: '#1C583880',
                            valueFormatter: (value) => valueFormatter(value),
                            highlightScope: {
                                highlight: "item",
                            }
                        },
                    ]}
                    xAxis={[{
                        scaleType: 'band',
                        data: ["Maize\nSeeds", "Round\nUp", "Gum\nBoots", "Bloom\nBuddy"],
                        disableTicks: true,
                    }]}
                    yAxis={[{
                        disableTicks: true,
                        valueFormatter: (value) => valueFormatter(value),
                    }]}
                    height={350}
                    margin={{
                        right: 20,
                        left: 60,
                    }}
                    borderRadius={8}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,

                        },
                    }}
                />
                <CustomLegend/>
            </Box>
        </>
    )
}
export default InventorySalesGraph;

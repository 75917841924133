import {Outlet, Route, Routes} from "react-router-dom";
import {InputDistributions} from "./InputDistributions";
import {InputDistributionsDashboard} from "./InputDistributionsDashboard";

const InputDistributionPage = () => {
    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<>
                    <InputDistributionsDashboard/>
                </>} />
                <Route path='dashboard' element={<InputDistributionsDashboard/>}/>
                <Route path='sales' element={<InputDistributions/>}/>
                <Route path=':inputId/'
                       element={
                           <>
                                {/*<FarmerDetails />*/}
                           </>
                       }
                />
            </Route>
        </Routes>
    )
}

export default InputDistributionPage
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {Field, Form, Formik} from "formik";
import {del, put} from "../../../../utils/ajax";
import {remoteRoutes} from "../../../../data/constants";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {IOption} from "../../../components/shared/forms/XAutoComplete";
import {IBatch, IBatchMovement} from "../interfaces/IBatch";
import {XRadioGroup} from "../../../components/shared/forms/XRadioGroup";
import {INVENTORY_BATCH_MOVEMENTS_CONSTANTS} from "../../../../data/redux/inventory/batchMovementsReducer";
import {INVENTORY_BATCHES_CONSTANTS} from "../../../../data/redux/inventory/batchesReducer";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {ReceiveBatchSchema} from "../schemas/InventorySchema";


type Props = {
    batch?: IBatch | null
    movement?: IBatchMovement | null
}

export const ReceiveBatchForm = ({batch, movement}: Props) => {

    const dispatch = useDispatch()

    const units: IOption[] = [
        {id: 'mt', label: "MT", disabled: false},
        {id: 'kg', label: "Kg", disabled: false},
    ]

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        remarks: '',
        quantity: null,
        unit: 'mt'
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const updatedBatch = {...batch, quantity: Number(values.quantity), unitName: values.unit}

        put(`${remoteRoutes.inventoryService}/batches/${batch?.id}`, updatedBatch, () => {

            CloseModal("receive-batch", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INVENTORY_BATCHES_CONSTANTS.UPDATE_BATCH,
                payload: updatedBatch
            })

            del(`${remoteRoutes.inventoryService}/movements/${movement?.id}`,
                () => {
                    dispatch({
                        type: INVENTORY_BATCH_MOVEMENTS_CONSTANTS.DELETE_BATCH_MOVEMENT,
                        payload: {id: movement?.id}
                    })

                    toast.success(toastMessages.default.success, overrideToastDefaults)

                },
                (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults)
                    setModalKey(Date.now());
                },
                () => {
                }
            )
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
        })
    }

    return (

        <Formik
            initialValues={initialValues}
            validationSchema={ReceiveBatchSchema}
            onSubmit={handleSubmit}>
            {({handleSubmit, isSubmitting, values}) => {

                return <ModalWrapper
                    id="receive-batch"
                    title={"Receive Batch"}
                    size="md"
                    position="top"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <Form>
                        <div className="row">

                            <div className="col-lg-12">
                                <Field
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    value={values.quantity}
                                    label="Quantity received"
                                    name="quantity"
                                />
                            </div>

                            <div className="col-lg-12">
                                <Field
                                    row={true}
                                    disabled={isSubmitting}
                                    component={XRadioGroup}
                                    value={values.unit}
                                    options={units}
                                    label="Units"
                                    name="unit"
                                />
                            </div>

                            {/*<div className="col-lg-12">*/}
                            {/*    <Field*/}
                            {/*        disabled={isSubmitting}*/}
                            {/*        component={XTextArea}*/}
                            {/*        value={values.remarks}*/}
                            {/*        label="Remarks"*/}
                            {/*        name="remarks"*/}
                            {/*    />*/}
                            {/*</div>*/}

                        </div>
                    </Form>
                </ModalWrapper>

            }}
        </Formik>


    )
}


import * as Yup from "yup";
import {ISystemProduct} from "../products/interfaces/ISystemProduct";
import {IdName} from "../inputs/interfaces/IInput";

export interface IHub {
    id?: string | null
    name?: string | null
    email?: string | null
    phoneNumber?: string
    district?: string
    subCounty?: string | null
    village?: string | null
    postalAddress?: string | null
    minimumAggregationUnit?: string | null
    parentHubId?: string | null
    childIds?: string[]
    products?: IProduct[]
    processes?: IProcess[]
    collectionCentres?: ICollectionCentre[]
    contactPersons?: IContactPerson[]
    costCentres?: ICostCentre[]
    inputs?: IInput[]
    inputCategories?: IInputCategory[]
    numberOfFarmers?: number | null
}

export interface ICategories {
    inputCategories?: INumberName[]
    productCategories?: INumberName[]
}

export interface INumberName {
    number: number,
    name: string
}


export interface IInputCategory {
    number: number
    name: string
}

export interface IProduct {
    id: string
    name: string
    category?: string
    description?: string
    units?: IUnit[]
    unitString?: string
}

export interface IIdNamePair {
    id: string
    name: string
}

export interface IProcess {
    id?: string
    name: string
    hubId?: string
    costCentres: IdName [] | []
    products: IdName [] | []
    costCentreStrings?: string
}


export interface ICollectionCentre {
    id?: string
    name: string
    district?: string
    subCounty?: string
    parish?: string
    village?: string
    latitude?: number
    longitude?: number
    hubId?: string
}

export interface IContactPerson {
    id?: string
    hubId?: string
    name: string
    email: string
    phoneNumber: string
    district?: string
    subCounty?: string
    parish?: string
    village?: string
}

export interface ICostCentre {
    id?: string
    hubId?: string
    name: string
    description?: string
    units?: []
}

export interface IInput {
    id: string
    name: string
    description?: string
    category: string
    categoryName?: string
    units?: IIdNamePair[]
    unitsString?: string
}

export interface IUnit {
    id?: string
    name: string
    displayName: string
}

export interface IHubProduct {
    hubId?: string
    products: IProduct[] | []
}

export const HubProductSchema = Yup.object({
    hubId: Yup.string().required('Required'),
    products: Yup.array().of(
        Yup.object().shape({
            id: Yup.string().required('Product ID is required'),
            label: Yup.string().required('Product name is required')
        })
    ).min(1, 'At least one product must be selected')
});
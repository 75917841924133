import {useDispatch} from "react-redux";
import {useAuth} from "../../../auth";
import {useEffect, useState} from "react";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {IContactPerson} from "../../interfaces/IHub";
import {put} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {SETTINGS_CONSTANTS} from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {districts} from "../../../../../data/districts";
import {Field, Formik} from "formik";
import {CollectionCentreSchema} from "../../collectionCenters/interfaces/CollectionCentreSchema";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {XTextField} from "../../../../components/shared/forms/XTextField";
import {XAutoComplete} from "../../../../components/shared/forms/XAutoComplete";

interface Props {
    initialValues: IContactPerson
}

const EditContactPersonForm = ({initialValues}: Props) => {

    const dispatch = useDispatch();
    const {currentUser} = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {
        const url = buildUrl(remoteRoutes.onboardingService, `/contact/persons/${values.id}`);
        const data: IContactPerson = {
            ...values,
            district: values.district.label,
        };

        put(url, data, (response) => {

            CloseModal("new-contact-person", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        }, () => {

        })
    };

    return (
        <Formik
            initialValues={{
                ...initialValues,
                district: initialValues.district ? {
                    label: initialValues.district,
                    value: initialValues.district
                } : null,
            }}
            onSubmit={handleSubmit}
            validationSchema={CollectionCentreSchema}
            enableReinitialize={true}
        >
            {({handleSubmit, isSubmitting, setFieldValue, values}) => (

                <ModalWrapper
                    id={"edit-contact-person"}
                    title="Edit Contact Person"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Phone Number"
                                name="phoneNumber"
                            />
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                onChange={(event: any, value: string) => setFieldValue('district', value)}
                                value={values.district}
                                label="District"
                                name="district"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Parish"
                                name="parish"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Village"
                                name="village"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            )}
        </Formik>
    );
}

export default EditContactPersonForm;
import React from "react";
import {Box} from "@mui/material";
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => (
    <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#AA0050',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Maize Seeds</span>
        </Box>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#5F1ED9',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Round Up</span>
        </Box>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#1F5A2D',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Gumboots</span>
        </Box>
    </Box>
);

const InputSalesPerformanceGraph = () => {

    return (
        <>
            <Box style={{
                width: "100%",
                height: "100%",
                maxWidth: "auto",
                maxHeight: 350,
            }}>
                <BarChart
                    series={[
                        {data: [4, 3, 1, 5], stack: 'A', label: 'Maize Seeds', color: '#AA0050'},
                        {data: [3, 4, 1, 6], stack: 'A', label: 'Round Up', color: '#5F1ED9'},
                        {data: [3, 4, 1, 6], stack: 'A', label: 'Gumboots', color: '#1C5838'},
                        {data: [3, 4, 1, 6], stack: 'A', label: 'Bloom Buddy', color: '#34C38F'},
                        {data: [3, 4, 1, 6], stack: 'A', label: 'Insecticide', color: '#DF901F'},
                    ]}
                    xAxis={[{
                        scaleType: 'band',
                        data: ["Masaka \n" + "Store", "Lira \n" + "Store", "Main \n" + "Store", "Central \n" + "Store"],
                        disableTicks: true,
                    }]}
                    yAxis={[{
                        disableTicks: true,
                    }]}
                    borderRadius={8}
                    grid={{horizontal: true}}

                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,
                        },
                    }}
                />


                <CustomLegend/>
            </Box>
        </>
    )
}
export default InputSalesPerformanceGraph;
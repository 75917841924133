import React from 'react';
import {ISideMenuItem, SideMenuItems} from '../../../utils/SideMenu';

interface SideMenuProps {
    activeItem: string;
    setActiveItem: (label: string) => void;
}

const SideMenu: React.FC<SideMenuProps> = ({activeItem, setActiveItem}) => {
    const sideMenuItems = SideMenuItems({activeItem});

    const handleClick = (label: string) => {
        setActiveItem(label);
    };

    return (
        <ul className="side-menu">
            {sideMenuItems.map((item) => (
                <li key={item.path} className={item.label === activeItem ? 'active' : 'side-menu-item'}>
                    <button className={`rounded-5 ${item.label === activeItem ? 'active' : ''}`} onClick={() => handleClick(item.label)}>
                        {item.label}
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default SideMenu;
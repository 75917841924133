import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React from "react";
import {ICostCentre} from "../interfaces/IHub";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import EditCostCentreForm from "./modals/EditCostCentreForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteCostCentreForm from "./modals/DeleteCostCentreForm";

type CostCentreDetailsProps = {
    close: () => void,
    show: boolean,
    costCentre: ICostCentre
}

const CostCentreDetails = ({close, show, costCentre}: CostCentreDetailsProps) => {


    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-cost-centre",
            dataToggle: "modal"
        },
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-cost-centre",
            dataToggle: "modal",
            deleteIcon: true
        },
    ];

    return (
        <>
            <XSidebarWrapper title={"Cost Centre Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{costCentre?.name.substring(0, 2).toUpperCase()}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{costCentre?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span className="badge text-bg-danger">
                                    Kw
                                </span>
                            </div>

                            <div className="action-buttons">

                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash}/>}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Description</strong><br/>
                            <span>{costCentre?.description ?? '-'}</span>
                        </div>
                    </div>
                </div>

            </XSidebarWrapper>

            <EditCostCentreForm initialValues={costCentre}/>
            <DeleteCostCentreForm item={costCentre}/>

        </>
    );
}

export default CostCentreDetails;
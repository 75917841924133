import React from "react";
import {Box} from "@mui/material";
import {BarChart} from "@mui/x-charts";

const CustomLegend = () => (
    <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#1C583880',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Amount in Credit</span>
        </Box>
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#1F5A2D',
                    marginRight: 1,
                }}
            />
            <span style={{fontSize: 12, fontWeight: 400, color: '#848484'}}>Received Cash</span>
        </Box>
    </Box>
);

const InputSalesGraph = () => {

    return (
        <>
            <Box style={{
                width: "100%",
                height: "100%",
                maxWidth: "auto",
                maxHeight: 350,
            }}>
                <BarChart
                    series={[
                        {data: [4, 3, 1, 5], stack: 'A', label: 'Received Cash', color: '#1F5A2D'},
                        {data: [3, 4, 1, 6], stack: 'A', label: 'Amount in Credit', color: '#1C583880'},
                    ]}
                    xAxis={[{
                        scaleType: 'band',
                        data: ["Maize \n" + "Seeds", "Round \n" + "Up", "Gum \n" + "Boots", "Bloom \n" + "Buddy"],
                        disableTicks: true,
                    }]}
                    yAxis={[{
                        disableTicks: true,
                    }]}

                    borderRadius={8}
                    grid={{horizontal: true}}
                    sx={{
                        '& .MuiChartsLegend-root': {
                            display: 'none',
                        },
                        '& .MuiChartsGrid-root line': {
                            stroke: '#EAEAEA',
                            strokeDasharray: '4 4',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                            display: 'none',
                        },
                        '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                            fill: '#A0A0A0',
                            fontsize: 14,
                            fontWeight: 400,
                        },
                    }}
                />
                <CustomLegend/>
            </Box>
        </>
    )
}
export default InputSalesGraph;
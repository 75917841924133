import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import {ISystemProduct} from "../products/interfaces/ISystemProduct";
import EditSystemProduct from "./modals/EditSystemProductForm";
import DeleteSystemProduct from "./modals/DeleteSystemProduct";
import {useAuth} from "../../auth";

type Props = {
    close: () => void,
    show: boolean,
    product: ISystemProduct
}

const SystemProductDetails = ({close, show, product}: Props) => {

    const auth = useAuth();
    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-system-product",
            dataToggle: "modal"
        },
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-system-product",
            dataToggle: "modal",
            deleteIcon: true
        },
    ];
    const productCategories = auth.categories?.productCategories;

    return (
        <>
            <XSidebarWrapper title={"Product Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">

                    {
                        product.imageUrl ?
                            <div className="rounded-1 bg-primary img-holder mb-2"
                                 style={{
                                     backgroundImage: `url(${product.imageUrl})`,
                                     backgroundRepeat: "no-repeat",
                                     backgroundSize: "cover"
                                 }}>
                            </div> :
                            <div className="py-3 px-4 rounded-1 bg-primary">
                                <h2>{product?.name.substring(0, 2).toUpperCase()}</h2>
                            </div>
                    }


                    <div className="py-3 w-100">
                        <h5>{product?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span className="badge text-bg-purple-light">
                                    {productCategories?.find(q => q.number == product.category)?.name}
                                </span>
                            </div>

                            <div className="action-buttons">

                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash}/>}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">

                        <div className="col-lg-6 mb-4">
                            <strong className="small fw-semibold">Brand</strong><br/>
                            <span>{product?.brand ?? '-'}</span>
                        </div>

                        <div className="col-lg-6 mb-4">
                            <strong className="small fw-semibold">Scientific Name</strong><br/>
                            <span>{product?.scientificName ?? '-'}</span>
                        </div>

                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Description</strong><br/>
                            <span>{product.description}</span>
                        </div>
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Units of Measurement</strong><br/>
                            <span>{(product.units?.length ? product.units.map(p => p.name).join(', ') : '-')}</span>
                        </div>
                    </div>
                </div>

            </XSidebarWrapper>
            <EditSystemProduct product={product}/>
            <DeleteSystemProduct item={product}/>
        </>
    );
}

export default SystemProductDetails;
import * as Yup from "yup";
import {IUnit} from "../../interfaces/IHub";

export interface ISystemProduct {
    id?: string
    name: string
    category?: number
    categoryName?: string
    otherProductCategory?: string
    description?: string
    units: IUnit[] | []
    unitString?: string
    imageUrl?: string
    scientificName?: string
    brand?: string

}

export interface IProductUnit {
    id: string
    name: string
}

export interface IUpdateProductUnit {
    productId: string
    unitId: string
    unitName: string
}

export interface IUpdateSystemProduct {
    id?: string
    name: string
    category?: number
    categoryName?: string
    otherProductCategory?: string
    description?: string
    units?: IUpdateProductUnit[]
    unitString?: string
    imageUrl?: string
}

export const SystemProductSchema = Yup.object({
    name: Yup.string().nullable().required('Required'),
    // category: Yup.number().nullable().required('Required')
})
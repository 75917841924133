import {Outlet, Route, Routes} from "react-router-dom";
import {Purchases} from "./Purchases";
import {Batches} from "./Batches";
import {BatchMovements} from "./BatchMovements";
import BatchSales from "./BatchSales";
import {Buyers} from "./Buyers";
import {Dashboard} from "./Dashboard";

const InventoryPage = () => {
    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<Dashboard/>}/>
                <Route path='dashboard' element={<Dashboard/>}/>
                <Route path='stock' element={<Purchases/>}/>
                <Route path='batches' element={<Batches/>}/>
                <Route path='buyers' element={<Buyers/>}/>
                <Route path='movements' element={<BatchMovements/>}/>
                <Route path='sales' element={<BatchSales/>}/>
            </Route>
        </Routes>
    )
}

export default InventoryPage
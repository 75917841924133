import React, {useEffect, useState} from 'react';
import {IMenuItem} from "../../../utils/MainMenu";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import {InventoryInnerMenu} from "../../modules/inventory/InventoryInnerMenu";
import {Modules} from "../../interfaces/Enums";
import {ProfilesInnerMenu} from "../../modules/profiling/ProfilesInnerMenu";
import {InputsInnerMenu} from "../../modules/input-distribution/InputsInnerMenu";

type Props = {
    module: Modules
}

export const InnerMenu = ({module}: Props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const activePage = location.pathname.toLowerCase()

    const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

    useEffect(() => {

        let items : IMenuItem[] = []

        if (module === Modules.Inventory){
            items = InventoryInnerMenu({activePage})
        }

        if (module === Modules.Profiles){
            items = ProfilesInnerMenu({activePage})
        }

        if (module === Modules.Inputs) {
            items = InputsInnerMenu({activePage})
        }

        setSubMenuItems(items)
    }, [activePage])

    return (
        <div className="w-100">
            <ul className="nav">
                {subMenuItems?.map((item, index) => (
                    <li key={index}
                        className={`nav-item ${item.isActive ? 'active' : ''} px-3 ms-3`}>

                        <button className={`nav-link`}
                           aria-current="page"
                           onClick={() => item.path ? navigate(item.path) : ""}>
                            {item.icon && <FontAwesomeIcon className="me-1" icon={item.icon}
                                                           size="1x"/>} {item.label}
                        </button>


                    </li>
                ))}

            </ul>
        </div>
    );
};